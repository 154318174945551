import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCategories } from '../../../store/actions/resource'
import { setLoading } from '../../../store/actions/app'
import CategoryTile from './CategoryTile'

const CategoriesList = ({ setShowNoBarcode, indexedDBInstance }) => {
  const dispatch = useDispatch()
  const { storeCategories } = useSelector(state => state.resource)
  const setLoadingCb = useCallback(l => dispatch(setLoading(l)), [dispatch])
  const handleSetCategories = useCallback(
    d => dispatch(setCategories(d)),
    [dispatch]
  )

  const getCategoriesChildren = categories => {
    const result = []

    categories.forEach(category => {
      if (category?.children) {
        const categoryChildren = []
        category.children.forEach(id => {
          const existing = categories.find(cat => cat._id === id && cat.visible)

          if (existing) {
            const coppyOfExisting = { ...existing }
            coppyOfExisting.isChild = true
            categoryChildren.push(coppyOfExisting)
          }
        })
        category.children = categoryChildren
      }
      result.push(category)
    })

    return result
  }

  const getCategories = useCallback(() => {
    if (indexedDBInstance) {
      indexedDBInstance.getCategories().then(categories => {
        const visibleCategories = categories.filter(
          category => category.visible
        )
        const childrenPopulated = getCategoriesChildren(visibleCategories)
        handleSetCategories(childrenPopulated)
        setLoadingCb(false)
      })
    }
  }, [handleSetCategories, indexedDBInstance, setLoadingCb])

  useEffect(() => {
    if (indexedDBInstance) {
      getCategories()
    }
  }, [getCategories, indexedDBInstance])

  return (
    <div className="category-list">
      {!!storeCategories?.length ? (
        storeCategories.sort((a, b) => a.position - b.position).map(category => (
          <CategoryTile
            key={category._id}
            category={category}
            setShowNoBarcode={setShowNoBarcode}
          />
        ))
      ) : (
        <h4>No categories found</h4>
      )}
    </div>
  )
}

export default CategoriesList
