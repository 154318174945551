import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { ListenersUtils, IndexedDB } from '@countr/utils'
import { CountrEvents } from '@countr/utils/build/models/countr'

import { setCart, setStore, setProducts, setProductById, deleteProductById } from '../store/actions/resource'
// import { useEffectOnce } from '../utils/hooks'
import { logError } from '../utils/error'

const RESOURCE = 'products'

const Listeners = React.memo(({ merchantId, storeId, deviceId, countrSdk, indexedDBInstance }) => {
  const db = useRef(null)

  const dispatch = useDispatch()
  const storeProducts = useSelector(state => state.resource.storeProducts)
  const storeProductsdRef = useRef()
  
  // eslint-disable-next-line no-unused-vars
  const [cartLocal, setCartLocal] = useState({})

  storeProductsdRef.current = storeProducts || []

  const setStoreCb = useCallback(st => dispatch(setStore(st)), [dispatch])
  const setProductCb = useCallback(product => dispatch(setProductById(product)), [dispatch])
  const deleteProductCb = useCallback(id => dispatch(deleteProductById(id)), [dispatch])
  const setCartCb = useCallback(c => dispatch(setCart(c)), [dispatch])
  const handleSetProducts = useCallback(
    d => dispatch(setProducts(d)),
    [dispatch]
  )

  const logListenerError = useCallback(
    (event, error) => {
      const msg = `Error while try to process listener event: ${event}`
      logError(countrSdk, merchantId, storeId, deviceId, msg, error)
    },
    [countrSdk, merchantId, storeId, deviceId]
  )

  const updateStore = useCallback(
    async ({ store: id }) => {
      try {
        const updatedStore = await countrSdk.stores.readOne(id)
        setStoreCb(updatedStore)
      } catch (error) {
        logListenerError(CountrEvents.STORE_UPDATED, error)
      }
    },
    [countrSdk.stores, setStoreCb, logListenerError]
  )

  const createProduct = useCallback(
    async id => {
      const product = await countrSdk.products.readOne(id)

      indexedDBInstance.searchByIdAndUpdateOrAdd(
        product,
        'products',
        'create'
      ).catch(async e => {
        logListenerError(CountrEvents.PRODUCT_CREATED, e)
      })

      indexedDBInstance.searchByIdAndUpdateOrAdd(
        product,
        'vproducts',
        'create'
      ).catch(async e => {
        logListenerError(CountrEvents.PRODUCT_CREATED, e)
      })

      handleSetProducts([...storeProductsdRef.current, product])
    },
    [countrSdk.products, handleSetProducts, indexedDBInstance, logListenerError]
  )

  const updateProduct = useCallback(
    async id => {
      const product = await countrSdk.products.readOne(id)
      
      setProductCb(product)
      
      indexedDBInstance.searchByIdAndUpdateOrAdd(
        product,
        'products',
        'update'
      ).catch(async e => {
        logListenerError(CountrEvents.PRODUCT_UPDATED, e)
      })

      indexedDBInstance.searchByIdAndUpdateOrAdd(
        product,
        'vproducts',
        'update'
      ).catch(async e => {
        logListenerError(CountrEvents.PRODUCT_UPDATED, e)
      })
    },
    [countrSdk.products, indexedDBInstance, logListenerError, setProductCb]
  )

  const deleteProduct = useCallback(
    async id => {
      indexedDBInstance.searchByIdAndDelete([id], 'products').catch(
        async e => {
          logListenerError(CountrEvents.PRODUCT_DELETED, e)
        }
      )

      deleteProductCb(id)
    },
    [deleteProductCb, indexedDBInstance, logListenerError]
  )

  const cartUpdate = useCallback(
    async ({ cart: id }) => {
      console.log('Listener cartUpdate')
      try {
        const cart = await countrSdk.carts.readOne(id)
        setCartLocal(cart)
        if (cart.server_modified) {
          setCartCb(cart)
        }
      } catch (error) {
        logListenerError(CountrEvents.CART_UPDATED, error)
      }
    },
    [countrSdk.carts, setCartCb, logListenerError]
  )

  const countrEvents = useMemo(() => {
    return [
      {
        event: `s${storeId}:${CountrEvents.STORE_UPDATED}`,
        callback: updateStore
      },
      {
        event: `s${storeId}:${CountrEvents.PRODUCT_CREATED}`,
        callback: createProduct
      },
      {
        event: `s${storeId}:${CountrEvents.PRODUCT_UPDATED}`,
        callback: updateProduct
      },
      {
        event: `s${storeId}:${CountrEvents.PRODUCT_DELETED}`,
        callback: deleteProduct
      },
      {
        event: `s${deviceId}:${CountrEvents.CART_UPDATED}`,
        callback: cartUpdate
      }
    ]
  }, [
    cartUpdate,
    createProduct,
    deleteProduct,
    deviceId,
    storeId,
    updateProduct,
    updateStore
  ])

  useEffect(() => {
    console.log("Listener useEffectOnce init");
    db.current = new IndexedDB(merchantId)
    db.current.addKey(RESOURCE, '_id')
    ListenersUtils.addBulkListeners(countrSdk, countrEvents)

    return () => {
      console.log("Listener useEffectOnce removed");
      ListenersUtils.removeBulkListeners(countrSdk, countrEvents)
    }
  }, [countrEvents, countrSdk, merchantId])
})

export default Listeners
