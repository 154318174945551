import { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { Trans, useTranslation } from 'react-i18next'

import WelcomeScreenDisplay from '../components/ui/WelcomeScreenDisplay'

import {
  setDevice,
  setSettings,
  setSelectedCategory
} from '../store/actions/resource'
import {
  setShowNix,
  setAutodirectPayment,
  setWaitAutodirectPayment
} from '../store/actions/app'

import {
  setRequestSampleCheck,
  setRequestFullBasketCheck,
  setRequestAgeCheck,
  setConfirmAgeCheck,
  setRequestAskForHelp,
  setUniqueEventId,
  setPendingSampleCheck,
  setFullBasketCheckCart
} from '../store/actions/notifications'

import countrSdk from '../utils/Countr'
import { updateDevice } from '../utils/device'
import data from '../assets/json/languages.json'

// import StartScreen from '../assets/images/countr_black.png'
import { PrinterUtils } from '../utils/printer'
import { useEffectOnce } from '../utils/hooks'
import { cancelNixCheck } from '../utils/asposqueue'

import './../styles/pages.scss'

const WelcomePage = ({ handleClearCartState }) => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { device, settings, store } = useSelector(state => state.resource)

  const resetLamp = useCallback(() => {
    PrinterUtils.sendToPrinter(
      device,
      'lamps',
      {
        r: 0,
        g: 255,
        b: 0,
        blink: false
      },
      {}
    )
  }, [device])

  useEffectOnce(() => {
    resetLamp()
  }, [resetLamp])

  // When flows return to the welcome-page we put back language
  // to Dutch
  useEffect(() => {
    i18n.changeLanguage('nl')
    handleChange('nl', true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n])

  const updateSettings = useCallback(d => dispatch(setSettings(d)), [dispatch])
  const setDeviceCb = useCallback(
    d => {
      dispatch(setDevice(d))
      updateDevice(countrSdk, d)
    },
    [dispatch]
  )

  const langs = data.languages

  const handleClick = (event, language) => {
    event.stopPropagation()
    i18n.changeLanguage(language)
    handleChange(language)
  }

  const goToMain = () => {
    clearReduxState()
    navigate('/main')
  }

  const handleChange = (newValue, doNotNavigate) => {
    const updatedSettings = {
      ...settings,
      language: newValue
    }
    updateSettings(updatedSettings)
    setDeviceCb({
      ...device,
      settings: {
        ...updatedSettings
      }
    })
    if (!doNotNavigate) {
      goToMain()
    }
  }

  const clearReduxState = () => {
    const existing = localStorage.getItem('currentcart:nix:checked')

    if (existing) {
      cancelNixCheck()
    }

    dispatch(setShowNix(false))
    dispatch(setAutodirectPayment(false))
    dispatch(setWaitAutodirectPayment(false))

    dispatch(setRequestSampleCheck(false))
    dispatch(setRequestFullBasketCheck(false))
    dispatch(setRequestAgeCheck(false))
    dispatch(setConfirmAgeCheck(false))
    dispatch(setRequestAskForHelp(false))
    dispatch(setUniqueEventId(''))
    dispatch(setPendingSampleCheck(false))
    dispatch(setFullBasketCheckCart({}))
    dispatch(setSelectedCategory({}))
    handleClearCartState()
  }

  return (
    <div className="welcome-page" onClick={goToMain}>
      <WelcomeScreenDisplay />
      <div className="welcome-info">
        <div className="welcome-text">
          <h1>
            <Trans i18nKey="welcome_to" /> {store.extras?.name || store.name}{' '}
            <br />
          </h1>
        </div>
        <div className="language-container">
          {langs.map(lang => (
            <div
              className="language"
              key={lang.name}
              onClick={event => handleClick(event, lang.name)}>
              <img src={`${lang.icon}`} alt={lang.name} />
              <span className="title">{lang.name.toUpperCase()}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default WelcomePage
