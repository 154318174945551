import { useSelector } from 'react-redux'
import './../../styles/ui.scss'

const Loader = () => {
  const loading = useSelector(state => state.app.loading)

  if (!loading) {
    return null
  }

  return (
    <div className="loader-container">
      <div className="spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default Loader
