import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { MdOutlineClose } from 'react-icons/md'
import { MdOutlineKeyboard } from 'react-icons/md'
import { MdOutlineKeyboardHide } from 'react-icons/md'

import { setSearch, setKeyboard } from './../../../store/actions/resource'
import './../../../styles/searchBar.scss'

const ProductSearch = ({ setShowNoBarcode }) => {
  const dispatch = useDispatch()
  const { search, isKeyboardShown } = useSelector(state => state.resource)

  const { t } = useTranslation()

  const onSearchChange = value => {
    setShowNoBarcode(false)
    dispatch(setSearch(value))
  }

  const clearSearch = () => {
    document.getElementById('search-input-text').value = ''
    dispatch(setSearch(''))
  }

  const toogleKeyboard = () => {
    dispatch(setKeyboard(!isKeyboardShown))
  }

  return (
    <div className="search-container">
      <div className="search-input-container">
        <input
          className="search-input-text"
          id="search-input-text"
          value={search || ''}
          type="text"
          placeholder={t('search_products')}
          onChange={event => onSearchChange(event.target.value)}
        />
        <div className="search-input-icon" onClick={() => toogleKeyboard()}>
          {isKeyboardShown ? <MdOutlineKeyboardHide /> : <MdOutlineKeyboard />}
        </div>
        <div className="search-input-icon" onClick={() => clearSearch()}>
          <MdOutlineClose />
        </div>
      </div>
    </div>
  )
}

export default ProductSearch
