import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import '../../../../styles/sparCart.scss'

const ButtonActionSection = ({ total, goToCheckout }) => {
  const { t } = useTranslation()

  const isCalculatingCart = useSelector(state => state.app.isCalculatingCart)

  return (
    <div className="cart-footer-sections">
      <button
        disabled={total <= 0 || isCalculatingCart}
        className={'kiosk-btn-container dark-contained-btn'}
        onClick={goToCheckout}>
        {`${t('pay')}`} €{total.toFixed(2)}
      </button>
    </div>
  )
}

export default ButtonActionSection
