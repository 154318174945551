export const logError = (sdk, merchant, store, device, message, info = {}) => {
  const error = {
    date: new Date(),
    user: merchant,
    store: store,
    device: device,
    source: 'kiosk',
    message: message,
    info: info
  }

  sdk.e.create(error)
}
