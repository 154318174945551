import { MdAttachMoney } from 'react-icons/md'
import ADYEN from './../../../assets/icons/adyen.jpg'
import CCV from './../../../assets/icons/ccv.jpg'
import PAYCONIQ from './../../../assets/icons/payconiq.jpg'
import WIPAY from './../../../assets/icons/wipay.png'
import './../../../styles/checkout.scss'

const PaymentButton = ({ payment, onClick }) => {
  const getIcon = () => {
    switch (payment.method) {
      case 'adyen':
        return <img src={ADYEN} alt="payment method" className="icon" />
      case 'ccv':
        return <img src={CCV} alt="payment method" className="icon" />
      case 'payconiq':
        return <img src={PAYCONIQ} alt="payment method" className="icon" />
      case 'wipay': 
        return <img src={WIPAY} alt="payment method" className="icon" />
      default:
        return <MdAttachMoney className="icon" />
    }
  }

  const handleClick = () => {
    onClick(payment)
  }

  return (
    <div className="payment-tiles ripple" onClick={handleClick}>
      <div className="payment-icon">{getIcon()}</div>
      <div className="description">
        <span className="title">{payment.name.toUpperCase()}</span>
        {payment.description && (
          <span className="sub-title">{payment.description}</span>
        )}
      </div>
    </div>
  )
}

export default PaymentButton
