export const handleChangeSettings = (
  settings,
  sectionName,
  itemIndex,
  value
) => {
  return settings.map(setting => {
    return setting.name === sectionName
      ? {
          ...setting,
          items: setting.items.map((item, i) =>
            i === itemIndex
              ? {
                  ...item,
                  value: value
                }
              : item
          )
        }
      : setting
  })
}

export const hasAdminPin = settings => {
  if (settings) {
    return !!settings?.admin_pin && !!settings?.admin_pin_enabled
  }

  return false
}

export const getAdminPin = settings => {
  if (settings) {
    return settings?.admin_pin || ''
  }

  return ''
}
