import { DeviceUtils, CartUtils } from '@countr/utils'
import { logSlack } from './utils'

import { store } from '../App'
import {
  setRequestAskForHelp,
  setUniqueEventId,
  setRequestSampleCheck,
  setPendingSampleCheck,
  setRequestFullBasketCheck,
  setFullBasketCheckCart
} from '../store/actions/notifications'
import { setShowNix, setWaitAutodirectPayment } from '../store/actions/app'

export const formatAsposEvent = ({
  statusId = 3539,
  type,
  priority,
  processed,
  actionRequired,
  extras = {}
}) => {
  const currentState = store.getState()
  const { store: countrStore, device } = currentState.resource
  const eventId = `${type}-${device._id}-${Math.floor(Date.now() / 1000)}`

  if (type === 'Nix') {
    localStorage.setItem('lastNixCheckEventId', eventId)
  }

  return {
    statusId,
    type,
    priority,
    processed,
    description: `${type} requested by Store ${countrStore.name} and Device ${device.name}`,
    actionRequired,
    uniqueEventId: eventId,
    message: {
      _id: device._id,
      ...extras
    }
  }
}

let localDesktopSocket = null
const local = JSON.parse(localStorage.getItem('CountrLite:LocalDesktop'))
const ip = local?.local_ip
if (DeviceUtils.isCountrDesktop()) {
  if (!ip) {
    const noIpError = `Failed to instantiate local socket connection`
    logSlack(noIpError)

    throw Error(noIpError)
  }

  const { REACT_APP_WS_PORT } = process.env
  const DYNAMIC_PORT = REACT_APP_WS_PORT || 2222

  localDesktopSocket = new WebSocket(`ws://${ip}:${DYNAMIC_PORT}`)
}

export const sendToDesktopIpc = (action, payload, isCustom = false) => {
  if (localDesktopSocket) {
    let intervalDesktopConnection

    const eventFormatted = !isCustom ? formatAsposEvent(payload) : payload
    const transactionId = localStorage.getItem('CountrLite:LastTransaction')
    eventFormatted.transactionId = transactionId

    const sendEvent = {
      ip: local,
      type: action,
      payload: eventFormatted,
      ws: localDesktopSocket
    }

    localDesktopSocket.onmessage = event => {
      const res = JSON.parse(event.data)
      const resParsed = JSON.parse(res.message)

      if (
        resParsed?.message?.EventType === 'Help' &&
        resParsed?.message?.StatusId === 3538
      ) {
        store.dispatch(setRequestAskForHelp(false))
      }

      if (
        resParsed?.message?.EventType === 'Nix' &&
        resParsed?.message?.StatusId === 3538
      ) {
        store.dispatch(setShowNix(false))
      }

      if (
        resParsed?.message?.EventType === 'SampleCheck' &&
        resParsed?.message?.StatusId
      ) {
        const statusId = resParsed.message.StatusId
        if (statusId === 3538 || statusId === 3537 || statusId === 3541) {
          if (resParsed?.message?.ContentJson) {
            const parsedContent = JSON.parse(resParsed.message.ContentJson)

            if (parsedContent?.fullCheck) {
              store.dispatch(setWaitAutodirectPayment(true))
            } else {
              store.dispatch(setWaitAutodirectPayment(false))
            }
          } else {
            store.dispatch(setWaitAutodirectPayment(false))
          }

          store.dispatch(setPendingSampleCheck(false))
          store.dispatch(setRequestSampleCheck(false))
        } else if (statusId === 3540) {
          store.dispatch(setPendingSampleCheck(true))
        }
      }

      if (
        resParsed?.message?.EventType === 'FullCheck' &&
        resParsed?.message?.StatusId
      ) {
        const statusId = resParsed.message.StatusId
        const currentState = store.getState()
        store.dispatch(
          setFullBasketCheckCart(
            CartUtils.emptyCart(currentState.resource.cart)
          )
        )

        if (statusId === 3539 || statusId === 3540) {
          store.dispatch(setRequestFullBasketCheck(true))
          store.dispatch(setUniqueEventId(resParsed.message.UniqueEventId))
        } else if (
          statusId === 3538 ||
          statusId === 3537 ||
          statusId === 3541
        ) {
          store.dispatch(setWaitAutodirectPayment(false))
          store.dispatch(setRequestFullBasketCheck(false))
        }
      }

      if (
        resParsed?.message?.EventType === 'ManualCheck' &&
        resParsed?.message?.StatusId
      ) {
        const statusId = resParsed.message.StatusId
        if (statusId === 3539) {
          store.dispatch(setPendingSampleCheck(true))
          store.dispatch(setRequestSampleCheck(true))
        } else if (
          statusId === 3538 ||
          statusId === 3537 ||
          statusId === 3541
        ) {
          store.dispatch(setPendingSampleCheck(false))
          store.dispatch(setRequestSampleCheck(false))
        } else if (statusId === 3540) {
          store.dispatch(setPendingSampleCheck(true))
        }
      }
    }

    if (localDesktopSocket?.readyState === 1) {
      console.log('Sending to IPC Main Electron')
      localDesktopSocket.send(JSON.stringify(sendEvent))
    } else {
      intervalDesktopConnection = setInterval(() => {
        logSlack(
          `Local socket not ready and retrying. Event ${JSON.stringify(
            sendEvent
          )}`
        )
        if (localDesktopSocket?.readyState === 1) {
          localDesktopSocket.send(JSON.stringify(sendEvent))
          clearInterval(intervalDesktopConnection)
          localDesktopSocket.close()
        }
      }, 200)
    }
  }
}

export const cancelNixCheck = () => {
  const currentState = store.getState()
  const eventId = localStorage.getItem('lastNixCheckEventId')

  if (!eventId) {
    return
  }

  sendToDesktopIpc(
    'asposQueueDispatcher',
    {
      type: 'FullCheck',
      priority: 1,
      processed: false,
      description: 'Nix check rejected, the item has been removed',
      actionRequired: false,
      uniqueEventId: eventId,
      message: { _id: currentState.resource.device._id },
      statusId: 3541
    },
    true
  )

  localStorage.removeItem('currentcart:nix:checked')
  localStorage.removeItem('lastNixCheckEventId')
  store.dispatch(setShowNix(false))
}
