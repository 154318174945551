export const getVariantName = (variant, lang = 'en') => {
  if (!!variant.translations && !!variant.translations.names) {
    return !!variant.translations.names[lang]
      ? variant.translations.names[lang]
      : variant.name
  }

  return variant.name
}

export const getAddonName = (addon, lang = 'en') => {
  if (!!addon.translations && !!addon.translations.names) {
    return !!addon.translations.names[lang]
      ? addon.translations.names[lang]
      : addon.name
  }
  return addon.name
}

export const getCategoryName = (category, lang = 'en') => {
  if (!!category.translations && !!category.translations.names) {
    return !!category.translations.names[lang]
      ? category.translations.names[lang]
      : category.name
  }
  return category.name
}
