import {
  REQUEST_SAMPLE_CHECK,
  REQUEST_FULL_BASKET_CHECK,
  REQUEST_AGE_CHECK,
  CONFIRMED_AGE_CHECK,
  REQUEST_ASK_HELP,
  UNIQUE_EVENT_ID,
  PENDING_SAMPLE_CHECK,
  FULL_BASKET_CHECK_CART
} from './actionTypes'

export const setRequestSampleCheck = requestSampleCheck => ({
  type: REQUEST_SAMPLE_CHECK,
  payload: requestSampleCheck
})

export const setRequestFullBasketCheck = requestFullBasketCheck => ({
  type: REQUEST_FULL_BASKET_CHECK,
  payload: requestFullBasketCheck
})

export const setRequestAgeCheck = requestAgeCheck => ({
  type: REQUEST_AGE_CHECK,
  payload: requestAgeCheck
})

export const setConfirmAgeCheck = confirmAgeCheck => ({
  type: CONFIRMED_AGE_CHECK,
  payload: confirmAgeCheck
})

export const setRequestAskForHelp = askForHelp => ({
  type: REQUEST_ASK_HELP,
  payload: askForHelp
})

export const setUniqueEventId = id => ({
  type: UNIQUE_EVENT_ID,
  payload: id
})

export const setPendingSampleCheck = pending => ({
  type: PENDING_SAMPLE_CHECK,
  payload: pending
})

export const setFullBasketCheckCart = cart => ({
  type: FULL_BASKET_CHECK_CART,
  payload: cart
})
