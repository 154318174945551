export const updateStore = (sdk, store) => {
  return sdk.stores.update(store._id, store)
}

export const hasTheme = store => {
  return !!store.extras && !!store.extras.themes && !!store.extras.themes.data
}

export const getCurrentTheme = store => {
  const { themes, currentTheme } = store.extras
  const themeNames = Object.keys(themes.data)
  let theme = themes.data[themeNames[0]]

  themeNames.forEach(name => {
    if (themes.data[name]._id === currentTheme) {
      theme = themes.data[name]
    }
  })

  return theme
}
