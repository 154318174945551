import { useCallback } from 'react'
import { Trans } from 'react-i18next'

import '../../../../styles/sparCart.scss'

const TotalSection = ({ items }) => {
  const getTotal = useCallback(() => {
    const sumAmount = items.reduce((acc, current) => {
      return acc + current.amount
    }, 0)
    return sumAmount
  }, [items])

  return (
    <div className="cart-footer-sections">
      <span id="cart-points-value">{getTotal()}</span>
      <span id="cart-points-text">
        <Trans i18nKey="quantity" />
      </span>
    </div>
  )
}

export default TotalSection
