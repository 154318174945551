import ReactPlayer from 'react-player'
import { useSelector } from 'react-redux'

import '../../styles/pages.scss'
import { useEffect, useState } from 'react'

const WelcomeScreenDisplay = () => {
  const { device } = useSelector(state => state.resource)

  const adInfoType = device.options?.adInfo?.adType ?? false
  const adUrlVideoFormatted = device.options?.adInfo?.adUrl ?? ''
  const photoAdUrlsLength = device.options?.adInfo?.photoAdUrls?.length ?? 0

  const [adUrlImageFormatted, setAdUrlImageFormatted] = useState('')

  useEffect(() => {
    if (Array.isArray(device.options.adInfo?.photoAdUrls)) {
      if (photoAdUrlsLength) {
        setAdUrlImageFormatted(device.options.adInfo.photoAdUrls[0])
        if (photoAdUrlsLength > 1) {
          let i = 0
          setInterval(() => {
            setAdUrlImageFormatted(device.options.adInfo.photoAdUrls[i])
            i++
            if (i === photoAdUrlsLength) {
              i = 0
            }
          }, 5000)
        }
      }
    }
  }, [device.options?.adInfo?.photoAdUrls, photoAdUrlsLength])

  return (
    <div className="vector">
      {adInfoType === 'Video' && adUrlVideoFormatted !== '' && (
        <ReactPlayer
          url={adUrlVideoFormatted}
          playing={true}
          width="95vw"
          height="800px"
          loop={true}
          volume={0}
          style={{ pointerEvents: 'none' }} // used to disable payers buttons
        />
      )}

      {adInfoType === 'Photos' && adUrlImageFormatted !== '' && (
        <img
          style={{
            width: '95vw',
            height: '800px'
          }}
          src={adUrlImageFormatted}
        />
      )}
    </div>
  )
}

export default WelcomeScreenDisplay
