import { useState } from 'react'
import { Modal } from '@countr/ui'
import { useTranslation } from 'react-i18next'

import './../../styles/screensaver.scss'

const SampleCheckModal = () => {
  const { t } = useTranslation()

  const [countdown, setCountdown] = useState(20)

  const handleCountdown = () => {
    if (countdown > 0) {
      setTimeout(() => {
        setCountdown(countdown - 1)
      }, 1000)
    }
    return ''
  }

  return (
    <Modal
      title=""
      showCancelBtn={false}
      showConfirmBtn={false}
      backdropClose={false}>
      <div
        style={{
          height: 450,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <h1 className="text-center">
          {t(`sample_check_msg_top`)}
          <br />
          {t(`sample_check_msg_bottom`)}
        </h1>
        {!pendingSampleCheck && <span>{handleCountdown()}</span>}
      </div>
    </Modal>
  )
}

export default SampleCheckModal
