import CountrClient from '@countr/sdk'
import { getCookie, setCookie } from './cookies'

class Countr {
  constructor() {
    if (!Countr.instance) {
      Countr.instance = this.createSdk()
    }

    return Countr.instance
  }

  createSdk() {
    const {
      REACT_APP_PROD,
      REACT_APP_STAGING,
      REACT_APP_CLIENT_ID,
      REACT_APP_CLIENT_SECRET,
      API_DOMAIN
    } = process.env
    const opts = {
      staging: REACT_APP_PROD !== 'true' && REACT_APP_STAGING === 'true',
      local: REACT_APP_PROD !== 'true' && REACT_APP_STAGING !== 'true',
      enableSocket: true
    }
    if(API_DOMAIN) opts.customDomain = API_DOMAIN

    const countrClient = new CountrClient({
      opts
    })

    countrClient.setClient({
      client_id: REACT_APP_CLIENT_ID,
      client_secret: REACT_APP_CLIENT_SECRET
    })

    countrClient.setToken({
      access_token: getCookie('access_token'),
      refresh_token: getCookie('refresh_token')
    })

    countrClient.on('refreshed', async token => {
      setCookie('access_token', token.access_token)
      setCookie('refresh_token', token.refresh_token)
    })

    countrClient.once('registered', token => {
      setCookie('access_token', token.access_token)
      setCookie('refresh_token', token.refresh_token)
      
      countrClient.setToken({
        access_token: token.access_token,
        refresh_token: token.refresh_token
      })
    })

    return countrClient
  }
}

const countrSdk = new Countr()
Object.freeze(countrSdk)

export default countrSdk
