import data from '../../../assets/json/languages.json'
import '../../../styles/language.scss'
const langs = data.languages

const LanguageModal = props => {
  const handleClick = language => {
    props.changeSelectedLanguage(language)
  }
  return (
    <>
      {langs?.length && (
        <div className="buttons">
          {langs.map(lang => (
            <div
              className="language-tiles"
              key={lang.name}
              onClick={() => handleClick(lang.name)}>
              <div className="language-icon">
                <img src={`${lang.icon}`} alt={lang.name} />
              </div>
              <div className="description">
                <span className="title">{lang.name.toUpperCase()}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default LanguageModal
