import Dexie from 'dexie'
import * as Comlink from 'comlinkjs'

const CountrWebWorker = Comlink.proxy(
  new Worker(`${window.location.origin}/countrworker.worker.js`)
)

const countrWebWorker = new CountrWebWorker()

export default class IndexedDBWrapper {
  static indexedDBInstance = null
  device = {}
  user = {}
  countrApi = null
  lastDelta = new Date('1970-01-01').toISOString()
  token = null
  type = 'products'

  constructor(user, device, token, type, callback) {
    this.token = token
    this.device = device
    this.user = user
    this.callback = callback
    this.type = type

    IndexedDBWrapper.indexedDBInstance = new Dexie(`${user._id}`)

    IndexedDBWrapper.indexedDBInstance.version(1).stores({
      products: '_id, position, name, visible, ean, *i_categories',
      categories: '_id, created_at, name, category_id',
      vproducts: '_id, position, name, ean, *i_categories',
      vcategories: '_id, created_at, name, category_id'
    })

    IndexedDBWrapper.indexedDBInstance.on('ready', () => {
      console.log('IndexedDBWrapper READY...')
    })

    IndexedDBWrapper.indexedDBInstance
      .open()
      .then(async () => {
        this.countrApi = await countrWebWorker
        await this.init(device)
      })
      .catch(e => {
        this.logError({
          msg: 'Failed to open IndexedDB Database',
          stack: e.stack
        })
      })

    // this.showEstimatedQuota()
  }

  async init(device) {
    // Saving current lastDelta for check deleted products after initialize
    const last = localStorage.getItem(`${device._id}_products_lastDelta`)
    if (last) {
      localStorage.setItem(`${device._id}_lastDelta_deleted`, last)
    }

    if (this.type === 'products') {
      await this.populateProducts(device)
    } else if (this.type === 'categories') {
      await this.populateCategories(device)
    } else {
      await Promise.all([
        this.populateProducts(device),
        this.populateCategories(device)
      ]).catch(e => {
        this.logError({
          msg: 'Error in Async Init IndexedDBWrapper method',
          stack: e.stack
        })
      })
    }

    if (this.callback) {
      this.callback(false)
    }

    localStorage.setItem('freshdelta', false)
  }

  logError = async obj => {
    const errorObj = {
      source: process.env.REACT_APP_ERROR_SOURCE,
      message: `${obj.msg}, users: ${this.user.username},
        _ID: ${this.user._id}, device id: ${this.device._id}`,
      user: this.user._id,
      store: this.device.store._id,
      device: this.device._id,
      stack: JSON.stringify(obj.stack),
      date: new Date().toISOString()
    }
    console.log(
      '🚀 ~ file: IndexedDBWrapper.js ~ line 80 ~ IndexedDBWrapper ~ errorObj',
      errorObj
    )

    // await AppInstances.getCountrSdk()
    // AppInstances.logError(errorObj)
  }

  async cleanTable(tableName) {
    try {
      await IndexedDBWrapper.indexedDBInstance[tableName].clear()
    } catch (e) {
      this.logError({
        msg: `Error trying to clean IndexedDB table named ${tableName}`,
        stack: e.stack
      })
    }
  }

  static clearDatabase() {
    try {
      return IndexedDBWrapper.indexedDBInstance.delete()
    } catch (e) {
      this.logError({
        msg: `Error trying to clean IndexedDB database`,
        stack: e.stack
      })
    }
  }

  async populateCategories(device, lastDelta) {
    const delta = this.calculateDelta(device, lastDelta, 'categories')

    if (delta === '1970-01-01T00:00:00.000Z') {
      IndexedDBWrapper.indexedDBInstance['categories'].clear()
      IndexedDBWrapper.indexedDBInstance['vcategories'].clear()
    }

    return await this.countrApi
      .getData(
        process.env.REACT_APP_API_SERVER,
        this.token,
        this.user._id,
        device.store._id,
        delta,
        {
          name: 'categories',
          endpoint: `devices/${device._id}/categories/delta`
        }
      )
      .then(() => {
        // Success returned and updating Delta date
        localStorage.setItem(
          `${device._id}_categories_lastDelta`,
          new Date('1970-01-01').toISOString() // Set delta to 1970 always so on each delta the full set is retrieved (handle deletions, etc.)
        )
      })
      .catch(console.log)
  }

  calculateDelta(device, lastDelta, type = 'products') {
    if (!device || !device._id) {
      throw new Error(
        'To calculate Delta date you have to provide a Device object'
      )
    }

    return lastDelta
      ? lastDelta
      : localStorage.getItem(`${device._id}_${type}_lastDelta`) ||
          new Date('1970-01-01').toISOString()
  }

  async populateProducts(device, lastDelta) {
    const delta = this.calculateDelta(device, lastDelta, 'products')

    if (delta === '1970-01-01T00:00:00.000Z') {
      localStorage.setItem('freshdelta', true)
      IndexedDBWrapper.indexedDBInstance['products'].clear()
      IndexedDBWrapper.indexedDBInstance['vproducts'].clear()
    }

    return await this.countrApi
      .getData(
        process.env.REACT_APP_API_SERVER,
        this.token,
        this.user._id,
        device.store._id,
        delta,
        {
          name: 'products',
          endpoint: `stores/${device.store._id}/products/delta`
        },
        [
          {
            name: 'ean',
            field: 'variants',
            item: 'ean'
          },
          {
            name: 'i_categories',
            field: 'categories',
            item: '_id'
          },
          {
            name: 'visible',
            field: 'visible'
          }
        ]
      )
      .then(() => {
        console.log('Refreshing Products and Categories Resolved')
        // Success returned and updating Delta date
        localStorage.setItem(
          `${device._id}_products_lastDelta`,
          new Date().toISOString() // Current date Delta
        )

        if (this.callback) {
          this.callback(false)
        }
      })
      .catch(e => {
        this.logError({
          msg: `Error trying to fetch Countr Api - populateProducts`,
          stack: e.stack
        })
      })
  }

  /**
   *
   */
  async showEstimatedQuota() {
    if (navigator.storage && navigator.storage.estimate) {
      const estimation = await navigator.storage.estimate()
      console.log(`Quota: ${estimation.quota}`)
      console.log(`Usage: ${estimation.usage}`)
    } else {
      this.logError({
        msg: `StorageManager not found - showEstimatedQuota`
      })
    }
  }

  /**
   *
   */
  async isStoragePersisted() {
    return (
      (await navigator.storage) &&
      navigator.storage.persisted &&
      navigator.storage.persisted().then(async isPersisted => {
        if (isPersisted) {
          console.log(':) Storage is successfully persisted.')
        } else {
          console.log(':( Storage is not persisted.')
          console.log('Trying to persist..:')
          if (await navigator.storage.persist()) {
            console.log(
              ':) We successfully turned the storage to be persisted.'
            )
          } else {
            console.log(':( Failed to make storage persisted')
          }
        }
      })
    )
  }

  /**
   * Search by field name
   * @param {String} name
   * @param {String} field
   * @param {String} collection
   */
  static async searchDbByFields(value, field, collection, sortBy) {
    const instanceFactory = IndexedDBWrapper.indexedDBInstance
    if (!instanceFactory) {
      window.location.href = '/'
      return
    }

    return instanceFactory
      .transaction('r', collection, async () => {
        const table = IndexedDBWrapper.indexedDBInstance.table(collection)

        const promises = []
        const resultPromises = []

        if (Array.isArray(field)) {
          field.forEach(async item => {
            promises.push(table.orderBy(item).uniqueKeys())
          })
        } else {
          throw new Error('Fields to search need to be an array')
        }

        const results = await Promise.all(promises)

        const matchProducts = results.flat().filter(prod => {
          if (typeof prod === 'string') {
            return ~prod.toLowerCase().indexOf(value.toLowerCase().trim())
          } else if (typeof prod === 'object' && prod.length === 1) {
            return ~prod[0].toLowerCase().indexOf(value.toLowerCase().trim())
          } else {
            return false
          }
        })

        if (Array.isArray(field)) {
          field.forEach(async item => {
            const currentQuery = table
              .where(item)
              .anyOfIgnoreCase(...matchProducts)

            if (sortBy) {
              currentQuery.sortBy('name')
            }

            resultPromises.push(currentQuery.toArray())
          })
        } else {
          throw new Error('Fields to search need to be an array')
        }

        return (await Promise.all(resultPromises)).flat()
      })
      .catch(e => {
        console.log(e)
      })
  }

  /**
   *
   */
  searchByIdAndUpdateOrAdd = async (item, collection, action) => {
    return IndexedDBWrapper.indexedDBInstance.transaction(
      'rw',
      collection,
      async () => {
        const table = IndexedDBWrapper.indexedDBInstance.table(collection)
        try {
          console.log(`Product ${item._id} - ${item.name} ${action}d`)
          if (action === 'create') {
            await table.add(item)
          } else {
            await table.update(item._id, item)
          }
        } catch (error) {
          console.log(
            '🚀 ~ file: pper ~ returnIndexedDBWrapper.indexedDBInstance.transaction ~ error',
            error
          )
          this.logError({
            msg: `Failed to add product ${item._id} - ${item.name} IndexedDB Database`,
            stack: JSON.stringify(error)
          })
          return null
        }
      }
    )
  }

  /**
   *
   */
  searchByIdAndDelete = (itemsId, collection) => {
    return IndexedDBWrapper.indexedDBInstance.transaction(
      'rw',
      collection,
      async () => {
        ;`Products ${itemsId} deleted`
        const table = IndexedDBWrapper.indexedDBInstance.table(collection)
        return table.bulkDelete(itemsId)
      }
    )
  }

  /**
   * Search by field name (Using to search barcode)
   * @param {String} name
   * @param {String} field
   * @param {String} collection
   */
  static async searchContainDbByFields(value, field, collection) {
    return IndexedDBWrapper.indexedDBInstance
      .transaction('r', collection, async () => {
        const table = IndexedDBWrapper.indexedDBInstance.table(collection)
        const names = await table.orderBy(field).uniqueKeys()

        const matchProducts = names.filter(
          ean =>
            ean &&
            ean.length >= 5 &&
            value.toLowerCase().indexOf((ean || '').toLowerCase()) >= 0
        )

        return table
          .where(field)
          .startsWithAnyOfIgnoreCase(...matchProducts)
          .toArray()
      })
      .catch(e => {
        // @TODO
        // send error collection
        console.log(e)
      })
  }

  /**
   * Search by field starting with value
   * @param {String} value
   * @param {String} field
   *
   * @TODO Refactor to check the search buy EAN
   */
  static searchDbByFieldsStartWith(value, field, collection) {
    const products = IndexedDBWrapper.indexedDBInstance.table(collection)
    return products.where(field).startsWithAnyOfIgnoreCase(value).toArray()
  }

  static getResourceCount(resource) {
    const res = IndexedDBWrapper.indexedDBInstance.table(resource)
    return new Promise((resolve, reject) => {
      try {
        resolve(res.count())
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   *
   */
  static getAllProducts() {
    const products = IndexedDBWrapper.indexedDBInstance.table('products')

    return new Promise((resolve, reject) => {
      try {
        resolve(products.toArray())
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   *
   */
  getProducts(sortBy = 'position', limit = 30) {
    const products = IndexedDBWrapper.indexedDBInstance.table('products')

    return new Promise((resolve, reject) => {
      try {
        resolve(products.orderBy(sortBy).limit(limit).sortBy(sortBy))
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   *
   */
  getCategories = () => {
    if (IndexedDBWrapper?.indexedDBInstance?.table) {
      try {
        const categories =
          IndexedDBWrapper.indexedDBInstance.table('categories')

        return new Promise((resolve, reject) => {
          try {
            resolve(categories.toArray())
          } catch (error) {
            reject(error)
          }
        })
      } catch (e) {
        return Promise.reject()
      }
    } else {
      return Promise.reject()
    }
  }
}
