import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { ProductUtils, BarcodeUtils } from '@countr/utils'
import onScan from 'onscan.js'

import { getCookie } from '../utils/cookies'
import { request } from '../utils/request'
import countrSdk from '../utils/Countr'

const Scan = ({ merchantId, cart, handleProduct, isWelcome }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isPaymentRunning = useSelector(state => state.app.isPaymentRunning)

  const goToMain = useCallback(() => {
    navigate('/main')
  }, [navigate])

  useEffect(() => {
    const init = async () => {
      if (!merchantId) {
        throw Error('Failed to instantiate IndexedDB to perform Scan')
      }
    }

    init()
  }, [merchantId, cart._id])

  const toggleIsCalculatingCart = useCallback(
    show => dispatch(setIsCalculatingCart(show)),
    [dispatch]
  )

  const handleScan = useCallback(
    async code => {
      if (isPaymentRunning) {
        return
      }

      if (isWelcome) {
        isWelcome(true)
      }

      toggleIsCalculatingCart(true)

      function handleScanProduct(product, order) {
        handleProduct(product, order)
      }

      const token = getCookie(ACCESS_TOKEN)
      const query = {
        text: code.length >= 10 && code[0] === '2' ? code.substr(0, 6) : code
      }

      // Add the scan query here later for Dexie
      // const dbProduct = await WorkerResourceLoader.searchDbByFields(code, ['ean'], 'products')
      const serverProducts = await request({
        token,
        query,
        resource: BARCODE_ENDPOINT
      })

      if (serverProducts?.length) {
        if (code.length >= 10 && code[0] === '2') {
          // Start with 2 and we do a full Match against the barcode
          const product = serverProducts.filter(_product => {
            return (
              _product.variants.filter(variant => {
                const eans = (variant.ean || '').split(',')
                return eans.indexOf(code.toLowerCase()) >= 0
              }).length > 0
            )
          })

          if (!!product && product.length) {
            product[0].variants = product[0].variants.map(el => {
              return {
                ...el,
                extras: {
                  ...el.extras,
                  scannedCode: code
                }
              }
            })
            handleScanProduct(product[0], [
              ProductUtils.PRODUCT_ACTION_ORDER.DEFAULT
            ])
          } else {
            // If the full match doesn't work
            // we search for the first 6 chars against the variants.ean first 6 chars
            // But that also ends with 000000
            const asposRecheckDynamic = serverProducts.filter(_product => {
              return (
                _product.variants.filter(variant => {
                  const eans = (variant.ean || '').split(',')
                  let eanFound = false
                  eans.forEach(eanval => {
                    if (
                      eanval.length >= 5 &&
                      code
                        .substring(0, 6)
                        .toLowerCase()
                        .indexOf(eanval.substring(0, 6).toLowerCase()) >= 0 &&
                      eanval.endsWith('000000')
                    )
                      eanFound = true
                  })
                  return eanFound
                }).length > 0
              )
            })

            // And we call dynamic for it
            if (asposRecheckDynamic && asposRecheckDynamic[0]) {
              // Calculate price and handle product as normal

              const calculated_price =
                parseInt(code.slice(-5, code.length - 1)) / 100
              asposRecheckDynamic[0].variants[0].price = !isNaN(
                calculated_price
              )
                ? parseFloat(calculated_price)
                : parseFloat(asposRecheckDynamic[0].variants[0].price)

              asposRecheckDynamic[0].variants =
                asposRecheckDynamic[0].variants.map(el => {
                  return {
                    ...el,
                    extras: {
                      ...el.extras,
                      scannedCode: code
                    }
                  }
                })

              handleScanProduct(asposRecheckDynamic[0], [
                ProductUtils.PRODUCT_ACTION_ORDER.DEFAULT
              ])
            } else {
              dispatch(setBarcodeProductNotFound(true))

              BarcodeUtils.barcodeNotFound(countrSdk, cart, code)

              setTimeout(() => {
                toggleIsCalculatingCart(false)
              }, 5000)
            }
          }
        } else {
          serverProducts[0].variants = serverProducts[0].variants.map(el => {
            return {
              ...el,
              extras: {
                ...el.extras,
                scannedCode: code
              }
            }
          })

          handleScanProduct(
            serverProducts[0],
            ProductUtils.getProductActionOrder(serverProducts[0])
          )
        }
      } else {
        BarcodeUtils.barcodeNotFound(countrSdk, cart, code)
        dispatch(setBarcodeProductNotFound(true))
      }

      goToMain()
      dispatch(setSelectedBarcode(code))
    },
    [
      cart,
      dispatch,
      goToMain,
      handleProduct,
      isPaymentRunning,
      isWelcome,
      toggleIsCalculatingCart
    ]
  )

  useEffect(() => {
    if (onScan?.isAttachedTo(document)) {
      return
    }

    onScan.attachTo(document, {
      suffixKeyCodes: [13], // enter-key expected at the end of a scan
      // Compatibility to built-in scanners in paste-mode (as opposed to keyboard-mode)
      reactToPaste: false,
      onScan: code => handleScan(code),
      minLength: 4
    })

    return () => {
      if (!!document.scannerDetectionData) {
        onScan.detachFrom(document)
      }
    }
  }, [handleScan])
}

export default Scan
