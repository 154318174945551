import {
  SET_ALL_RESOURCES,
  SET_UUID,
  SET_AUTH,
  SET_USER,
  SET_DEVICE,
  SET_STORE,
  SET_CART,
  SET_SEARCH,
  SET_LAST_TRANSACTION,
  SET_SETTINGS,
  SET_KEYBOARD,
  SET_SEARCH_VALUE,
  SET_PRODUCTS,
  SET_CATEGORIES,
  SELECTED_CATEGORY,
  SELECTED_BARCODE,
  BARCODE_PRODUCT_NOT_FOUND,
  UPDATE_PRODUCTS_BY_ID,
  DELETE_PRODUCTS_BY_ID
} from './actionTypes'

export const setAllResources = resources => ({
  type: SET_ALL_RESOURCES,
  payload: resources
})

export const setUUID = uuid => ({
  type: SET_UUID,
  payload: uuid
})

export const setAuth = () => ({
  type: SET_AUTH
})

export const setUser = user => ({
  type: SET_USER,
  payload: user
})

export const setDevice = device => ({
  type: SET_DEVICE,
  payload: device
})

export const setStore = store => ({
  type: SET_STORE,
  payload: store
})

export const setCart = cart => ({
  type: SET_CART,
  payload: cart
})

export const setProductById = product => ({
  type: UPDATE_PRODUCTS_BY_ID,
  payload: product
})

export const deleteProductById = id => ({
  type: DELETE_PRODUCTS_BY_ID,
  payload: id
})

export const setSearch = value => ({
  type: SET_SEARCH,
  payload: value
})

export const setLastTransaction = last => ({
  type: SET_LAST_TRANSACTION,
  payload: last
})

export const setSettings = settings => ({
  type: SET_SETTINGS,
  payload: settings
})

export const setKeyboard = value => ({
  type: SET_KEYBOARD,
  payload: value
})

export const setSearchValue = value => ({
  type: SET_SEARCH_VALUE,
  payload: value
})

export const setProducts = products => ({
  type: SET_PRODUCTS,
  payload: products
})

export const setCategories = categories => ({
  type: SET_CATEGORIES,
  payload: categories
})

export const setSelectedCategory = category => ({
  type: SELECTED_CATEGORY,
  payload: category
})

export const setSelectedBarcode = barcode => ({
  type: SELECTED_BARCODE,
  payload: barcode
})

export const setBarcodeProductNotFound = barcode => ({
  type: BARCODE_PRODUCT_NOT_FOUND,
  payload: barcode
})
