import { CartUtils } from '@countr/utils'
import { MdArrowDownward } from 'react-icons/md'
import { Trans } from 'react-i18next'
import SparCartItem from './SparCartItem'

import '../../../../styles/sparCart.scss'

const SparCartItemsList = ({
  cartItems,
  handleRemoveItem,
  handleAmountChange,
  currency,
  showNoBarcode
}) => {
  return (
    <div
      id="spar-cart-items-list"
      className={cartItems?.length ? 'spar-cart-with-items' : ''}>
      {cartItems.map(item => (
        <SparCartItem
          key={CartUtils.getCartEntryId(item)}
          product={item}
          handleRemoveItem={handleRemoveItem}
          handleAmountChange={handleAmountChange}
          currency={currency}
        />
      ))}
      {!cartItems?.length && showNoBarcode && (
        <div className="show-arrow">
          <h1>
            <Trans i18nKey="start_scanning" />
          </h1>
          <div className="arrow bounce">
            <MdArrowDownward />
          </div>
        </div>
      )}
    </div>
  )
}

export default SparCartItemsList
