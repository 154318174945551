import IndexedDbWrapper from './utils/IndexedDbWrapper'

class WorkerResourceLoader extends IndexedDbWrapper {
  store = null
  user = {}
  device = {}

  constructor(user, device, lastDelta = null) {
    super(user, device, lastDelta)

    this.user = user
    this.device = device
  }

  /**
   *
   * Search products by indexed fields
   * @param {String} name
   */
  static searchDbByFields(value, field, collection, sortBy) {
    return IndexedDbWrapper.searchDbByFields(value, field, collection, sortBy)
  }

  static getAllCategories() {
    return IndexedDbWrapper.getCategories()
  }

  static getVisibleProducts(sortBy, limit) {
    return IndexedDbWrapper.getProducts(sortBy, limit)
  }

  /**
   * Service Worker Message helper method with MessageChannel instantiation
   * @param {String} msg
   */
  send_message_to_sw(msg) {
    return new Promise(function (resolve, reject) {
      // Create a Message Channel
      const msg_chan = new MessageChannel()

      // Handler for recieving message reply from service worker
      msg_chan.port1.onmessage = function (event) {
        if (event.data.error) {
          reject(event.data.error)
        } else {
          resolve(event.data)
        }
      }

      // Send message to service worker along with port for reply
      navigator.serviceWorker.controller.postMessage(msg, [msg_chan.port2])
    })
  }
}

export default WorkerResourceLoader
