import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Trans } from 'react-i18next'

import { TransactioUtils } from '@countr/utils'

import { setLastTransaction } from '../../../store/actions/resource'
import { setCurrentTransactionToPrint } from '../../../store/actions/app'
import { createTransaction } from '../../../utils/transaction'
import countrSdk from '../../../utils/Countr'

import './../../../styles/checkout.scss'

const EVENTS = {
  CUSTOM_PAYMENT: 'custom-payment',
  CUSTOM_PAYMENT_START: 'custom-payment-start',
  CUSTOM_PAYMENT_CANCEL: 'custom-payment-cancel'
}
const METHODS = {
  PAYMENT_STARTED: 'paymentStarted',
  MESSAGE_RECEIVED: 'messageReceived',
  CONTINUE_WITH_PAYMENT: 'continueWithPayment',
  HANDLE_RESULT: 'handleResult',
  HANDLE_ERROR: 'handleError'
}

const RunningPayment = ({ store, cart, payment, close }) => {
  const ws = useRef()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const setLast = useCallback(l => dispatch(setLastTransaction(l)), [dispatch])
  const setTransactionToPrint = useCallback(
    transaction => dispatch(setCurrentTransactionToPrint(transaction)),
    [dispatch]
  )

  const { device } = useSelector(state => state.resource)
  const lastTransaction = useSelector(state => state.resource.lastTransaction)
  const [message, setMessage] = useState('Payment started')
  const [extraInfo, setExtraInfo] = useState({})
  const [qrCode, setQrCode] = useState(null)

  const handleError = useCallback(() => {
    toast.error(<Trans i18nKey="payment_not_successful" />)
    close()
  }, [close])

  const handleResult = useCallback(
    result => {
      ws.current.close()
      if (!!result?.success) {
        navigate('/receipt-screen')
        const { printData: clientTicket } = result
        const { name, method } = payment

        const receipt = TransactioUtils.createReceiptNumber(
          lastTransaction,
          store.store_id,
          device.device_id
        )
        const paymentEntry = TransactioUtils.createPaymentEntry(
          parseFloat(cart.total),
          name,
          method,
          result,
          clientTicket
        )

        const body = TransactioUtils.createTransactionBody(
          cart,
          device,
          paymentEntry,
          receipt,
          'kiosk'
        )

        createTransaction(countrSdk, body, cart._id).then(
          transaction => {
            setTransactionToPrint(transaction)
            setLast(lastTransaction + 1)
            localStorage.setItem('last_receipt', JSON.stringify(receipt))
            // Comment this to make the e2e faster, later we make an ENV_VAR test
            toast.success(transaction.receipt_id)
            console.log('printing receipt')
            close(transaction)
          },
          error => {
            console.log('---', error)
            toast.error(JSON.stringify(error))
          }
        )
      } else {
        handleError(result)
      }
    },
    [
      cart,
      close,
      setTransactionToPrint,
      device,
      handleError,
      lastTransaction,
      navigate,
      payment,
      setLast,
      store.store_id
    ]
  )

  const onMessage = useCallback(
    e => {
      if (e.data) {
        const event = JSON.parse(e.data)

        if (event.event) {
          // console.log(`- event.event`, event.event)
          switch (event.event) {
            case METHODS.PAYMENT_STARTED:
              paymentStarted(event.payload)
              break
            case METHODS.MESSAGE_RECEIVED:
              messageReceived(event.payload)
              break
            case METHODS.HANDLE_RESULT:
              handleResult(event.payload)
              break
            case METHODS.HANDLE_ERROR:
              handleError(event.payload)
              break
            default:
              break
          }
        }
      }
    },
    [handleError, handleResult]
  )

  useEffect(() => {
    const initPayment = () => {
      const local = JSON.parse(localStorage.getItem('CountrLite:LocalDesktop'))
      const { store_id } = store
      const { device_id } = device

      if (!local) {
        throw new Error('Desktop not found')
      }

      const sendEvent = {
        ip: local.local_ip,
        type: EVENTS.CUSTOM_PAYMENT,
        payload: {
          method: payment.method,
          extras: payment.extras,
          amount: Math.round(parseFloat(cart.total) * 100), // Amount should be in cents
          reference: TransactioUtils.getPaymentReference(store_id, device_id),
          currency: cart.currency.code,
          device_id: device.device_id,
          checkoutId: cart._id
        }
      }

      const { REACT_APP_WS_PORT } = process.env
      const DYNAMIC_PORT =
        device?.settings?.desktopListenerPort ?? (REACT_APP_WS_PORT || 2222)

      ws.current = new WebSocket(`ws://${local.local_ip}:${DYNAMIC_PORT}`)
      ws.current.onerror = handleError
      ws.current.onmessage = onMessage
      ws.current.onopen = () => {
        // Start payment
        if (ws.current.readyState === 1) {
          ws.current.send(JSON.stringify(sendEvent))
        }
      }
    }

    initPayment()
    // We have an error here that trigger the payment twice
    // Refactor later and remove exhaustive-deps error
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const messageReceived = payload => {
    // console.log(`- payload`, payload)
    if (!!payload.message) {
      setMessage(payload.message)
    }
  }

  const paymentStarted = payload => {
    if (!!payload.extra) {
      setExtraInfo(payload.extra)

      if (!!payload.extra.paymentQR) {
        setQrCode(payload.extra.paymentQR)
      }
    }
  }

  return (
    <div className="running-payment">
      <div className="display-messages">
        <Trans i18nKey={`${message.toLowerCase().replace(/ /g, '_')}`}>
          {message}
        </Trans>
      </div>
      {extraInfo && qrCode && (
        <img
          alt="payment qr code"
          draggable="false"
          width="240"
          height="240"
          src={qrCode}
        />
      )}
    </div>
  )
}

export default RunningPayment
