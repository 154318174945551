import { MdAdd, MdRemove } from 'react-icons/md'

import '../../../../styles/sparCart.scss'

const AddRemoveButtons = ({
  productAmount,
  handleChangeAmountButton,
  disableButtons
}) => {
  const activeColor = '#0077AF'
  const disabledColor = '#D6D6D6'
  const canRemove = productAmount > 1

  return (
    <div className="spar-cart-add-remove-container">
      {!disableButtons && (
        <span
          onClick={() => handleChangeAmountButton('remove')}
          className={`spar-cart-round-btn ${
            canRemove
              ? ' add-remove-btn-container'
              : 'add-remove-btn-container-disabled'
          }`}>
          <MdRemove color={canRemove ? activeColor : disabledColor} size={22}/>
        </span>
      )}
      <span className="spar-cart-item-amount-container">{productAmount}</span>

      {!disableButtons && (
        <span
          className="spar-cart-round-btn add-remove-btn-container"
          onClick={() => handleChangeAmountButton('add')}>
          <MdAdd color={activeColor} size={22}/>
        </span>
      )}
    </div>
  )
}

export default AddRemoveButtons
