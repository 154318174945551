import { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Trans } from 'react-i18next'

import { TransactioUtils } from '@countr/utils'

import { setLastTransaction } from '../../../store/actions/resource'
import { setCurrentTransactionToPrint } from '../../../store/actions/app'
import { createTransaction } from '../../../utils/transaction'
import countrSdk from '../../../utils/Countr'

import './../../../styles/checkout.scss'

const BasicPayment = ({ store, cart, payment, close }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const setLast = useCallback(l => dispatch(setLastTransaction(l)), [dispatch])
  const device = useSelector(state => state.resource.device)
  const lastTransaction = useSelector(state => state.resource.lastTransaction)

  const setTransactionToPrint = useCallback(
    transaction => dispatch(setCurrentTransactionToPrint(transaction)),
    [dispatch]
  )

  useEffect(() => {
    const handleResult = () => {
      navigate('/receipt-screen')
      const { name, method } = payment

      const receipt = TransactioUtils.createReceiptNumber(
        lastTransaction,
        store.store_id,
        device.device_id
      )

      const paymentEntry = TransactioUtils.createPaymentEntry(
        parseFloat(cart.total),
        name,
        method,
        {},
        ''
      )

      const body = TransactioUtils.createTransactionBody(
        cart,
        device,
        paymentEntry,
        receipt,
        'kiosk'
      )

      createTransaction(countrSdk, body, cart._id).then(
        transaction => {
          setTransactionToPrint(transaction)
          setLast(lastTransaction + 1)
          // toast.success(transaction.receipt_id)
          close(transaction)
        },
        error => {
          console.log('---', error)
          toast.error(JSON.stringify(error))
        }
      )
    }

    handleResult()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="running-payment">
      <div className="display-messages">
        <Trans i18nKey="payment_started" />
      </div>
    </div>
  )
}

export default BasicPayment
