import { useTranslation } from 'react-i18next'

import { CartUtils } from '@countr/utils'
import { setFullBasketCheckCart } from '../../../../store/actions/notifications'
import SparCartItem from './SparCartItem'
import { useSelector, useDispatch } from 'react-redux'
import '../../../../styles/sparCart.scss'

const FinishFullBasketCheck = ({
  handleShowFinishBasketCheck,
  countrSdk,
  handleRemoveItem,
  currency
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { fullBasketCart } = useSelector(state => state.notifications)
  const { cart } = useSelector(state => state.resource)

  const handleAddToFullBasketCheckCart = (item, action) => {
    const itemCoppy = { ...item }

    let updatedCart = { ...fullBasketCart }
    if (action === 'add') {
      itemCoppy.amount = 1

      const cartEntryId = CartUtils.getCartEntryId(itemCoppy)
      const existing = fullBasketCart?.items.find(
        p => CartUtils.getCartEntryId(p) === cartEntryId
      )

      if (existing) {
        updatedCart = CartUtils.changeCartEntryAmount(
          countrSdk,
          fullBasketCart,
          itemCoppy,
          action
        )
      } else {
        const originalIndex = cart.items.findIndex(
          p => CartUtils.getCartEntryId(p) === cartEntryId
        )
        if (
          fullBasketCart?.items?.length < originalIndex ||
          originalIndex < 0
        ) {
          updatedCart.items.push(itemCoppy)
        } else {
          fullBasketCart.items.splice(originalIndex, 0, itemCoppy)
        }
      }
    } else {
      itemCoppy.amount -= 1
      updatedCart = CartUtils.changeCartEntryAmount(
        countrSdk,
        fullBasketCart,
        itemCoppy,
        action
      )
    }
    dispatch(setFullBasketCheckCart(updatedCart))
  }

  const handleCartsAmounts = missingAmount => {
    let result = 0
    cart.items.forEach(item => {
      const cartEntryId = CartUtils.getCartEntryId(item)
      const scannedItem = fullBasketCart.items.find(
        i => CartUtils.getCartEntryId(i) === cartEntryId
      )

      const check = missingAmount
        ? scannedItem && item.amount > scannedItem.amount
        : scannedItem && item.amount < scannedItem.amount

      if (check) {
        console.log(
          '🚀 ~ file: FinishFullBasketCheck.js ~ line 71 ~ handleMissingItemsAmount ~ scannedItem',
          scannedItem
        )
        console.log(
          '🚀 ~ file: FinishFullBasketCheck.js ~ line 72 ~ handleMissingItemsAmount ~ item.amount - scannedItem.amount',
          item.amount - scannedItem.amount
        )
        result += item.amount - scannedItem.amount
      } else if (!scannedItem) {
        result += item.amount
      }
    })

    return Math.abs(result)
  }

  const missingItemsAmount = handleCartsAmounts(true)
  const extraItemsAmount = handleCartsAmounts()

  return (
    <div className="carts-to-check-container">
      <div className="checked-items-container">
        <div className="finish-basket-check">
          {!!missingItemsAmount && (
            <h3>
              {t('missing_items_amount')}:
              <span className="items-amount">{missingItemsAmount}</span>
            </h3>
          )}
          <h3>
            {t('extra_items_scanned')}:
            <span className="items-amount">{extraItemsAmount}</span>
          </h3>
          <button onClick={handleShowFinishBasketCheck}>{t('back')}</button>
        </div>
        {fullBasketCart.items.map(item => (
          <SparCartItem
            key={CartUtils.getCartEntryId(item)}
            product={item}
            handleRemoveItem={handleRemoveItem}
            handleAmountChange={handleAddToFullBasketCheckCart}
            currency={currency}
            disableButtons
            disableRemove
          />
        ))}
      </div>
    </div>
  )
}

export default FinishFullBasketCheck
