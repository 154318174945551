import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import appReducer from './reducers/app'
import resourceReducer from './reducers/resource'
import notificationsReducer from './reducers/notifications'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const middlewareEnhancer = applyMiddleware()

const enhancers = [middlewareEnhancer]
const composedEnhancers = composeWithDevTools(...enhancers)
const persistConfig = {
  key: 'root',
  storage
}

const rootReducer = combineReducers({
  app: appReducer,
  resource: resourceReducer,
  notifications: notificationsReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default () => {
  const store = createStore(persistedReducer, {}, composedEnhancers)
  const persistor = persistStore(store)
  return { store, persistor }
}
