import { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PrinterUtils } from '../utils/printer'
import { MdOutlineReceiptLong } from 'react-icons/md'

import './../styles/pages.scss'

const isFood = false

const ReceiptScreen = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [counter, setCounter] = useState(10)
  const { device, store } = useSelector(state => state.resource)

  const currentTransactionToPrint = useSelector(
    state => state.app.currentTransactionToPrint
  )

  const printReceipt = useCallback(() => {
    try {
      PrinterUtils.receipt(
        device,
        currentTransactionToPrint,
        true,
        store,
        currentTransactionToPrint.currency.code,
        () => {},
        false,
        false,
        false,
        t
      )
    } catch (error) {
      console.log(error)
    }

    navigate('/welcome-page')
  }, [currentTransactionToPrint, device, navigate, store, t])

  const nonReceipt = () => {
    if (isFood) {
      printReceipt()
    }

    navigate('/welcome-page')
  }

  useEffect(() => {
    if (currentTransactionToPrint?.items?.length) {
      printReceipt()
    }
  }, [currentTransactionToPrint, printReceipt])

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    if (!counter) {
      printReceipt()
      navigate('/welcome-page')
    }

    return () => clearInterval(timer)
  }, [counter, navigate, printReceipt])

  return (
    <div className="receipt-container">
      <h1>
        <Trans i18nKey="receipt_type" />
      </h1>
      <div className="receipt-container-text">
        <h2 className="main-text">De betaling is gelukt</h2>
        <h1 className="main-text">Dank je en tot ziens</h1>
        <h2 className="main-text">
          Vergeet de bon niet voor een eventuele controle
        </h2>
        <MdOutlineReceiptLong size={160} color="#878787" />
        <h2 className="sub-text">The payment was successful</h2>
        <h2 className="sub-text">Thank you and goodbye</h2>
        <h2 className="sub-text">{`Don't forget the receipt for a possible check`}</h2>
      </div>
      {!isFood && (
        <div className="receipt-buttons">
          <button className="full-button" onClick={nonReceipt}>
            <Trans i18nKey="no_receipt" />
          </button>
          <button
            className="short-button"
            disabled={!currentTransactionToPrint && !isFood ? true : false}
            onClick={printReceipt}>
            <Trans i18nKey="receipt" />
          </button>
        </div>
      )}
    </div>
  )
}

export default ReceiptScreen
