import { PersistGate } from 'redux-persist/integration/react'
import configureStore from './store/configureStore'
import { Provider } from 'react-redux'
import CountrApp from './CountrApp'

const { store, persistor } = configureStore()

export { store }

function App() {
  console.log('v: 1.0.0')
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CountrApp />
      </PersistGate>
    </Provider>
  )
}

export default App
