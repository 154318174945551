import { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setDevice, setSettings } from '../../../store/actions/resource'
import countrSdk from '../../../utils/Countr'
import { updateDevice } from '../../../utils/device'
import { Modal } from '@countr/ui'
import { Trans, useTranslation } from 'react-i18next'
import LanguageModal from './LanguageModal'
import './../../../styles/ui.scss'

const LanguageSelection = () => {
  const { i18n } = useTranslation()
  const [openModal, setOpenModal] = useState(false)
  const dispatch = useDispatch()
  const { device, settings } = useSelector(state => state.resource)
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language)
  const [showImage, setShowImage] = useState(false)

  const updateSettings = useCallback(d => dispatch(setSettings(d)), [dispatch])
  const setDeviceCb = useCallback(
    d => {
      dispatch(setDevice(d))
      updateDevice(countrSdk, d)
    },
    [dispatch]
  )

  const languageHandler = () => {
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  const changeSelectedLanguage = language => {
    i18n.changeLanguage(language)
    setCurrentLanguage(language)
    handleChange(language)
    handleClose()
  }

  const handleChange = newValue => {
    const updatedSettings = {
      ...settings,
      language: newValue
    }
    updateSettings(updatedSettings)
    setDeviceCb({
      ...device,
      settings: {
        ...updatedSettings
      }
    })
  }

  const getImage = () => {
    return `/images/countriesFlags/${currentLanguage}.png`
  }

  useEffect(() => {
    setShowImage(false)
    setCurrentLanguage(i18n.language)
    setTimeout(() => {
      setShowImage(true)
    }, 100)
  }, [i18n.language])

  return (
    <>
      <button className="lang-btn" onClick={languageHandler}>
        {showImage && (
          <>
            <img src={getImage()} alt={currentLanguage} />
            <span>{currentLanguage.toUpperCase()}</span>
          </>
        )}
      </button>
      {openModal && (
        <Modal
          title={<Trans i18nKey="change_language" />}
          handleClose={handleClose}
          backdropClose={false}
          showCancelBtn={true}
          showConfirmBtn={false}
          confirmLabel={<Trans i18nKey="close" />}>
          <LanguageModal changeSelectedLanguage={changeSelectedLanguage} />
        </Modal>
      )}
    </>
  )
}

export default LanguageSelection
