import { useCallback, useMemo, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'

import { ControlItem, Button } from '@countr/ui'

import IndexedDbWrapper from '../../utils/IndexedDbWrapper'
import countrSdk from '../../utils/Countr'
import { getCookie, expireCookie } from '../../utils/cookies'
import { Trans, useTranslation } from 'react-i18next'
import { updateStore, hasTheme } from '../../utils/store'
import { setTheme, setLoading } from '../../store/actions/app'
import { cancelNixCheck } from '../../utils/asposqueue'

import '../../styles/pages.scss'

const version = require('../../../package.json').version

const LANGUAGES = [
  { _id: 'en', name: 'English' },
  { _id: 'nl', name: 'Nederlands' },
  { _id: 'ar', name: 'العربية' },
  { _id: 'de', name: 'Deutsch' },
  { _id: 'el', name: 'Ελληνικά' },
  { _id: 'es', name: 'Español' },
  { _id: 'fr', name: 'Français' },
  { _id: 'is', name: 'Íslenska' },
  { _id: 'it', name: 'Italiano' },
  { _id: 'no', name: 'Norsk' },
  { _id: 'pt', name: 'Português' }
]

const AccountSettings = props => {
  const navigate = useNavigate()
  const { i18n, t } = useTranslation()
  const dispatch = useDispatch()

  const PIN_DIRECTIONS = [
    { _id: 'r', name: t('right') },
    { _id: 'l', name: t('left') },
    { _id: 't', name: t('top') },
    { _id: 'b', name: t('bottom') }
  ]

  const currentTheme = useSelector(state => state.app.theme)
  const {
    storeCategories,
    user: merchant,
    store,
    settings,
    device
  } = useSelector(state => state.resource)

  const storeCategoriesParsed = [
    { name: 'None', _id: '_none_' },
    ...storeCategories
  ]

  const [currentLanguage, setCurrentLanguage] = useState(i18n.language)
  const [themeState, setThemeState] = useState(currentTheme?._id ?? '')
  const [defaultCategory, setDefaultCategory] = useState(
    store.extras?.defaultCategory ?? ''
  )

  const setThemeCb = useCallback(t => dispatch(setTheme(t)), [dispatch])
  const setLoadingCb = useCallback(l => dispatch(setLoading(l)), [dispatch])

  const indexedDBInstance = useRef()

  const themes = useMemo(() => {
    if (!hasTheme(store)) {
      return []
    }
    const { themes } = store.extras
    return Object.keys(themes.data).map(key => ({
      ...themes.data[key]
    }))
  }, [store])

  const setLanguage = language => {
    i18n.changeLanguage(language)
    setCurrentLanguage(language)
    props.handleChange('language', language)
  }

  const handleChangeTheme = value => {
    setThemeState(value)
    const theme = themes.find(t => t._id === value)
    setThemeCb(theme)
    updateStore(countrSdk, {
      ...store,
      extras: {
        ...store.extras,
        currentTheme: value
      }
    })
  }

  const handleChangeDefaultCategory = value => {
    setDefaultCategory(value)

    updateStore(countrSdk, {
      ...store,
      extras: {
        ...store.extras,
        defaultCategory: value
      }
    })
  }

  const handleRefresh = async type => {
    setLoadingCb(true)
    const token = getCookie('access_token')

    if (!merchant?._id) {
      throw Error(`Merchant required to instantiate indexedb`)
    }

    localStorage.removeItem(`${device._id}_${type}_lastDelta`)
    localStorage.removeItem(`freshdelta`)

    indexedDBInstance.current = new IndexedDbWrapper(
      merchant,
      device,
      token,
      type,
      setLoadingCb
    )
  }

  const handleResetState = () => {
    localStorage.removeItem('persist:root')
    cancelNixCheck()
    window.location.reload()
  }

  const handleLogOut = () => {
    expireCookie('access_token')
    expireCookie('refresh_token')
    navigate('/login')
  }

  return (
    <div className="section">
      <div className="section-title">
        <span>
          <Trans i18nKey={`account`} />
        </span>
      </div>
      <div>
        <Button
          label={<Trans i18nKey="refresh_products" />}
          onClick={() => handleRefresh('products')}
          styles={{ padding: 15 }}
          color="primary"
        />
      </div>

      <div>
        <Button
          label={<Trans i18nKey="refresh_categories" />}
          onClick={() => handleRefresh('categories')}
          styles={{ padding: 15 }}
          color="primary"
        />
      </div>

      <div>
        <ControlItem
          title={<Trans i18nKey={`pin`} />}
          value={settings.admin_pin}
          handleChange={value => props.handleChange('admin_pin', value)}
          type="text"
        />
      </div>
      <div>
        <ControlItem
          title={<Trans i18nKey={`admin_pin`} />}
          value={settings.admin_pin_enabled}
          handleChange={value => props.handleChange('admin_pin_enabled', value)}
          type="boolean"
        />
      </div>
      <div>
        <ControlItem
          title={t('language')}
          value={currentLanguage}
          handleChange={setLanguage}
          type="select"
          options={LANGUAGES}
        />
      </div>
      <div>
        <ControlItem
          title={t('pin_direction')}
          value={settings.pin_direction}
          handleChange={value => props.handleChange('pin_direction', value)}
          type="select"
          options={PIN_DIRECTIONS}
        />
      </div>
      {hasTheme(store) && (
        <div>
          <ControlItem
            title={t('themes')}
            value={themeState}
            handleChange={handleChangeTheme}
            type="select"
            options={themes}
          />
        </div>
      )}
      {!!storeCategories?.length && (
        <div>
          <ControlItem
            title={t('initial_category')}
            value={defaultCategory}
            handleChange={handleChangeDefaultCategory}
            type="select"
            options={storeCategoriesParsed}
          />
        </div>
      )}
      <div>
        <ControlItem
          title={<Trans i18nKey={`auto_print`} />}
          value={settings.auto_print}
          handleChange={value => props.handleChange('auto_print', value)}
          type="boolean"
        />
      </div>

      <div>
        <ControlItem
          title={<Trans i18nKey={`no_print_delivery`} />}
          value={settings.no_print_delivery}
          handleChange={value => props.handleChange('no_print_delivery', value)}
          type="boolean"
        />
      </div>

      <div>
        <Button
          label={<Trans i18nKey="reset_state" />}
          onClick={handleResetState}
          styles={{ padding: 15 }}
          color="secondary"
        />
      </div>
      <div>
        <Button
          label={<Trans i18nKey="sign_out" />}
          onClick={handleLogOut}
          styles={{ padding: 15 }}
          color="secondary"
        />
      </div>
      <div>
        <p>{version}</p>
      </div>
    </div>
  )
}

export default AccountSettings
