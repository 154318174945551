/**
 * Create a GET request to api (params is a obj with the follow fields)
 * @param  {string} token - authorization token
 * @param  {string} resource - endpoint path
 * @param  {string} query - query obj to be transform to query params
 * @returns {Promise} http request promise
 */
export const request = ({ token, resource, query }) => {
  if (!token) {
    throw Error(`Missing token, resource ${resource} query ${query}`)
  }
  
  const URL = process.env.REACT_APP_API_SERVER
  const queryParams = !!query ? `?${queryString(query)}` : ''
  
  const headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`
  }

  return fetch(`${URL}/${resource}${queryParams}`, {
    method: 'GET',
    headers
  }).then(
    response => response.json(),
    error => error
  )
}

/**
 * Transform query obj to query parms string
 * @param {[key: string]: string} queryObj - key/value
 * @returns {string} Query params string joined with &
 */
function queryString(queryObj) {
  return Object.keys(queryObj)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryObj[key])}`)
    .join('&')
}
