import { useState, useCallback, useRef } from 'react'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { ToastContainer } from 'react-toastify'

import { ThemeProvider } from 'styled-components'
import WebFont from 'webfontloader'
import debounce from 'lodash.debounce'

import { Modal, useTheme, GlobalStyles } from '@countr/ui'
import { CartUtils, ProductUtils } from '@countr/utils'

import { useEffectOnce } from './utils/hooks'

import Listeners from '../src/components/Listeners'
import Scan from '../src/components/Scan'

import {
  setShowNix,
  setIsCalculatingCart,
  setLoading,
  setShowProductDescription
} from './store/actions/app'

import { setCart, setAllResources } from './store/actions/resource'
import { setFullBasketCheckCart } from './store/actions/notifications'

import { getCookie } from './utils/cookies'
import IndexedDbWrapper from './utils/IndexedDbWrapper'

import { updateCart } from './utils/cart'
import countrSdk from './utils/Countr'

import themes from './assets/json/themes.json'

import Header from './components/ui/Header'
import Loader from './components/ui/Loader'

import Landing from './pages/Landing'
import WelcomePage from './pages/WelcomePage'
import Login from './pages/Login'
import Main from './pages/Main'
import Checkout from './pages/Checkout'
import Payment from './pages/Payment'
import Settings from './pages/Settings'

import EndPage from './pages/EndPage'
import ReceiptScreen from './pages/ReceiptScreen'

import 'react-toastify/dist/ReactToastify.css'
import './styles/main.scss'

function RequireAuth({ children }) {
  const isAuth = useSelector(state => state.resource.isAuth)

  if (!isAuth) {
    return <Navigate to="/login" replace />
  }

  return children
}

function calculateItemTotal(cart) {
  return cart.items.reduce((total, el) => total + (el.amount || 1), 0)
}

function CountrApp() {
  const dispatch = useDispatch()

  const [modalContent, setModalContent] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [actionOrder, setActionOrder] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [selectedVariant, setSelectedVariant] = useState(null)
  const [selectedAddons, setSelectedAddons] = useState(null)
  const [dynamicValue, setDynamicValue] = useState(null)
  const [soldByWeightAmount, setSoldByWeightAmount] = useState(0)
  const [showNoBarcode, setShowNoBarcode] = useState(true)

  const isAuth = useSelector(state => state.resource.isAuth)
  const {
    user: merchant,
    store,
    cart,
    device
  } = useSelector(state => state.resource)

  const setLoadingCb = useCallback(l => dispatch(setLoading(l)), [dispatch])
  const toggleShowNix = useCallback(
    show => dispatch(setShowNix(show)),
    [dispatch]
  )
  const toggleIsCalculatingCart = useCallback(
    show => dispatch(setIsCalculatingCart(show)),
    [dispatch]
  )

  const { requestFullBasketCheck, fullBasketCart } = useSelector(
    state => state.notifications
  )

  const requestFullBasketCheckRef = useRef(null)
  requestFullBasketCheckRef.current = requestFullBasketCheck

  const { theme, getFonts, setMode } = useTheme(themes.data)
  const currentTheme = useSelector(state => state.app.theme)

  const showProductDescription = useSelector(
    state => state.app.showProductDescription
  )

  const settingShowProductDescription = useCallback(
    (show, description) =>
      dispatch(setShowProductDescription({ show, description })),
    [dispatch]
  )

  useEffectOnce(() => {
    WebFont.load({
      google: {
        families: getFonts()
      }
    })
  }, [getFonts])

  const indexedDBInstance = useRef()
  const memoizedRefreshDeltaCallback = useCallback(() => {
    const token = getCookie('access_token')

    if (!merchant?._id) {
      throw Error(`Merchant required to instantiate indexedb`)
    }

    indexedDBInstance.current = new IndexedDbWrapper(
      merchant,
      device,
      token,
      null,
      setLoadingCb
    )
  }, [device, merchant, setLoadingCb])

  useEffectOnce(() => {
    if (isAuth) {
      console.log('Is authenticated and fetching delta...')
      memoizedRefreshDeltaCallback()
    }
  }, [memoizedRefreshDeltaCallback])

  const setAllCb = useCallback(r => dispatch(setAllResources(r)), [dispatch])
  const setCartCb = useCallback(
    cart => {
      if (requestFullBasketCheckRef?.current) {
        countrSdk.carts.calculate(cart).then(cartUpdated => {
          dispatch(setFullBasketCheckCart(cartUpdated))
          if (calculateItemTotal(cart) !== calculateItemTotal(cartUpdated)) {
            console.log(`Some items in cart not yet calculated - waiting`)
          } else {
            toggleIsCalculatingCart(false)
          }
        })
      } else {
        updateCart(countrSdk, cart).then(cartUpdated => {
          // If the current cart has more items than in the returned / calculated cart then another response yet to come
          if (calculateItemTotal(cart) !== calculateItemTotal(cartUpdated)) {
            console.log(`Some items in cart not yet calculated - waiting`)
          } else {
            console.log('Allowed to pay...')
            toggleIsCalculatingCart(false)

            if (cartUpdated.server_modified) {
              dispatch(setCart(cartUpdated))
            }
          }
        })
      }
    },
    [dispatch, toggleIsCalculatingCart]
  )

  const handleCancel = () => {
    toggleIsCalculatingCart(false)
    setModalContent(null)
    setSelectedProduct(null)
    setSelectedVariant(null)
    setSelectedAddons(null)
    setDynamicValue(null)
    setSoldByWeightAmount(0)
    setActionOrder([])
    setShowModal(false)
  }

  const handleSelectVariant = variant => {
    if (actionOrder.length) {
      setSelectedVariant(variant)
      handleProduct(selectedProduct, actionOrder)
    } else {
      addProduct(selectedProduct, variant, store._id, 1)
      handleCancel()
    }
  }

  const updateCartServerDebounced = useRef(
    debounce(setCartCb, 500, { leading: false, trailing: true })
  )
  const addProduct = (product, variant, storeId, amount, addons = []) => {
    const cartEntry = CartUtils.createCartEntry(
      product,
      variant,
      storeId,
      amount,
      addons
    )

    if (!requestFullBasketCheck) {
      const updatedCart = CartUtils.addItemToCart(countrSdk, cart, cartEntry)
      dispatch(setCart(updatedCart))

      // Debounce it to update after users stops clicking
      updateCartServerDebounced.current(updatedCart)
    } else {
      const cartEntryId = CartUtils.getCartEntryId(cartEntry)
      const existing = fullBasketCart?.items.find(
        p => CartUtils.getCartEntryId(p) === cartEntryId
      )

      const updatedCart = CartUtils.addItemToCart(
        countrSdk,
        fullBasketCart,
        cartEntry
      )

      if (!existing && updatedCart?.items?.length) {
        updatedCart.items.push(updatedCart.items[0])
        updatedCart.items.shift()
      }

      dispatch(setFullBasketCheckCart(updatedCart))
      // Pick up any discount from server
      updateCartServerDebounced.current(updatedCart)
    }
    setShowNoBarcode(true)
  }

  const handleProduct = (product, order) => {
    const currentAction = order.shift()

    if (
      !requestFullBasketCheck &&
      product.options?.minimum_age &&
      product.options.minimum_age >= 18
    ) {
      try {
        const isCurrentCartNixChecked = localStorage.getItem(
          'currentcart:nix:checked'
        )

        if (isCurrentCartNixChecked !== cart._id) {
          toggleShowNix(true)
          localStorage.setItem('currentcart:nix:checked', cart._id)
        }
      } catch (error) {
        console.log('Not running as Desktop, Queue will be disabled')
      }
    }

    if (currentAction === ProductUtils.PRODUCT_ACTION_ORDER.DEFAULT) {
      addProduct(product, product.variants[0], store._id, 1)
    } else {
      setModalContent(currentAction)
      setSelectedProduct(product)
      setActionOrder(order)
      setShowModal(true)
    }
  }

  const handleClearCartState = () => {
    handleCancel()
    setShowNoBarcode(true)
  }

  useEffectOnce(() => {
    if (currentTheme?._id) {
      setMode(currentTheme)
    }
  }, [setMode, currentTheme])

  return (
    <ThemeProvider theme={theme} style={{ fontFamily: theme.font }}>
      <GlobalStyles theme={theme} />
      <Router>
        {isAuth && !!merchant?._id && !!store?._id && !!device?._id && (
          <Listeners
            indexedDBInstance={indexedDBInstance.current}
            countrSdk={countrSdk}
            merchantId={merchant._id}
            storeId={store._id}
            deviceId={device._id}
          />
        )}
        <Header>
          {!!merchant?._id && (
            <Scan
              cart={cart}
              merchantId={merchant._id}
              store={store}
              handleProduct={handleProduct}
              isWelcome={false}
            />
          )}
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/welcome-page"
              element={
                <RequireAuth>
                  <WelcomePage
                    handleProduct={handleProduct}
                    handleClearCartState={handleClearCartState}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/receipt-screen"
              element={
                <RequireAuth>
                  <ReceiptScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/end-page"
              element={
                <RequireAuth>
                  <EndPage />
                </RequireAuth>
              }
            />
            <Route
              path="/main"
              element={
                <RequireAuth>
                  <Main
                    indexedDBInstance={indexedDBInstance}
                    selectedVariant={selectedVariant}
                    handleSelectVariant={handleSelectVariant}
                    handleProduct={handleProduct}
                    actionOrder={actionOrder}
                    modalContent={modalContent}
                    selectedProduct={selectedProduct}
                    showModal={showModal}
                    selectedAddons={selectedAddons}
                    dynamicValue={dynamicValue}
                    setSoldByWeightAmount={setSoldByWeightAmount}
                    soldByWeightAmount={soldByWeightAmount}
                    handleCancel={handleCancel}
                    setSelectedAddons={setSelectedAddons}
                    addProduct={addProduct}
                    setAllCb={setAllCb}
                    setShowNoBarcode={setShowNoBarcode}
                    showNoBarcode={showNoBarcode}
                    setCartCb={updateCartServerDebounced}
                    setDynamicValue={setDynamicValue}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/checkout"
              element={
                <RequireAuth>
                  <Checkout />
                </RequireAuth>
              }
            />
            <Route
              path="/payment"
              element={
                <RequireAuth>
                  <Payment handleProduct={handleProduct} />
                </RequireAuth>
              }
            />
            <Route
              path="/settings"
              element={
                <RequireAuth>
                  <Settings />
                </RequireAuth>
              }
            />
          </Routes>
        </Header>
      </Router>
      <Loader />

      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />

      {showProductDescription.show && (
        <Modal
          handleClose={() => settingShowProductDescription(false, '')}
          title={showProductDescription.currentProductName}
          showCancelBtn={false}
          showConfirmBtn={true}
          backdropClose={true}
          confirmAction={() => {
            settingShowProductDescription(false, '')
          }}>
          <div>{showProductDescription.description}</div>
        </Modal>
      )}
    </ThemeProvider>
  )
}

export default CountrApp
