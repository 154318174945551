import { CartUtils } from '@countr/utils'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setFullBasketCheckCart } from '../../../../store/actions/notifications'

import SparCartItem from './SparCartItem'
import FinishFullBasketCheck from './FinishFullBasketCheck'
import '../../../../styles/sparCart.scss'

const FullBasketCheckLists = ({
  showFinishBasketCheck,
  handleShowFinishBasketCheck,
  updateCartServerDebounced,
  countrSdk,
  handleRemoveItem,
  handleAmountChange,
  currency
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { fullBasketCart } = useSelector(state => state.notifications)
  const { cart } = useSelector(state => state.resource)

  const handleFormatCartItems = updated => {
    const items = []

    updated?.items.forEach(i => {
      const originalIndex = cart.items.findIndex(
        p => CartUtils.getCartEntryId(p) === CartUtils.getCartEntryId(i)
      )

      if (updated?.items?.length < originalIndex || originalIndex < 0) {
        items.push(i)
      } else {
        items.splice(originalIndex, 0, i)
      }
    })

    return items
  }

  const handleAddToFullBasketCheckCart = async (item, action) => {
    const itemCoppy = { ...item }

    let updatedCart = { ...fullBasketCart }
    updatedCart.date = new Date()
    updatedCart.updated_at = new Date()
    updatedCart.server_modified = false
    if (action === 'add') {
      itemCoppy.amount = 1

      const cartEntryId = CartUtils.getCartEntryId(itemCoppy)
      const existing = fullBasketCart?.items.find(
        p => CartUtils.getCartEntryId(p) === cartEntryId
      )

      if (existing) {
        updatedCart = CartUtils.changeCartEntryAmount(
          countrSdk,
          updatedCart,
          itemCoppy,
          action
        )
      } else {
        updatedCart = CartUtils.addItemToCart(countrSdk, updatedCart, itemCoppy)
      }
    } else {
      itemCoppy.amount -= 1
      updatedCart = CartUtils.changeCartEntryAmount(
        countrSdk,
        updatedCart,
        itemCoppy,
        action
      )
    }

    updatedCart.items = handleFormatCartItems(updatedCart)

    dispatch(setFullBasketCheckCart(updatedCart))
    updateCartServerDebounced.current(updatedCart)
  }
  return (
    <div id="spar-cart-items-list" className="spar-cart-with-items">
      {showFinishBasketCheck ? (
        <FinishFullBasketCheck
          handleShowFinishBasketCheck={handleShowFinishBasketCheck}
        />
      ) : (
        <>
          <div className="carts-to-check-container">
            <div className="original-cart-container">
              <h3 className="title-container">{t('original_items')}</h3>
              {cart.items.map(item => (
                <SparCartItem
                  key={CartUtils.getCartEntryId(item)}
                  product={item}
                  handleRemoveItem={handleRemoveItem}
                  handleAmountChange={handleAddToFullBasketCheckCart}
                  currency={currency}
                  hideExtraInfo
                  disableRemove
                />
              ))}
            </div>

            <div className="checked-items-container">
              <h3 className="title-container">{t('checked_items')}</h3>
              {fullBasketCart.items.map(item => (
                <SparCartItem
                  key={CartUtils.getCartEntryId(item)}
                  product={item}
                  handleRemoveItem={handleRemoveItem}
                  handleAmountChange={handleAmountChange}
                  currency={currency}
                  disableButtons
                  hideExtraInfo
                />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default FullBasketCheckLists
