import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  setSelectedBarcode,
  setBarcodeProductNotFound,
  setSearch, setKeyboard
} from '../../../../store/actions/resource'
import '../../../../styles/sparCart.scss'
import ProductSearch from '../../Products/ProductSearch'
import { Modal } from '@countr/ui'
import ReactKeyboard from '../../ReactKeyboard'
import { toast } from 'react-toastify'

const SparCartHeader = ({
  showNoBarcodeProducts,
  handleShowNoBarcodeProducts,
  setShowNoBarcode
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { selectedBarcode, barcodeProductNotFound, isKeyboardShown } = useSelector(
    state => state.resource
  )
  const [showBarcode, setShowBarcode] = useState(false)
  const [keyboardInput, setKeyboardInput] = useState('')

  useEffect(() => {
    if (selectedBarcode) {
      setShowBarcode(true)
    }

    setTimeout(() => {
      dispatch(setBarcodeProductNotFound(false))
      setShowBarcode(false)
      dispatch(setSelectedBarcode(''))
    }, 5000)
  }, [selectedBarcode, dispatch])

  const visibleBarcode = !!selectedBarcode?.length && showBarcode

  const handleKeyboardChange = (button) => {
    setKeyboardInput(button)
  }

  const cancelSearch = () => {
    dispatch(setKeyboard(false));
  }

  const confirmSearch = () => {
    if (keyboardInput.length < 3) {
      return toast.error(t('min_chars'));
    }
    dispatch(setSearch(keyboardInput));
    dispatch(setKeyboard(false));
    setShowNoBarcode(false)
  }

  return (
    <div id="spar-cart-header">
      {isKeyboardShown ? (
        <Modal
          handleClose={cancelSearch}
          confirmAction={() => { confirmSearch(); }}
        >
          <ReactKeyboard
            placeholder={t("search_products")}
            onChange={handleKeyboardChange}
            onEnter={confirmSearch}
          />
        </Modal>
      ) : (null)}
      <ProductSearch setShowNoBarcode={setShowNoBarcode} />
      <div id="cart-top-btns-container" >
        {/* {!!selectedBarcode?.length && showBarcode && ( */}
        <div
          className="product-barcode"
          style={visibleBarcode ? {} : { color: '#fff' }}>
          {barcodeProductNotFound ? (
            <span style={{ color: 'red' }}>
              {t('scan_code_not_exist').replace('{barcode}', selectedBarcode)}
            </span>
          ) : (
            <span>
              {t('code')}: {selectedBarcode}
            </span>
          )}
        </div>
        {/* )} */}
        {!showNoBarcodeProducts && (
          <button
            onClick={handleShowNoBarcodeProducts}
            className={`kiosk-btn-container ${showNoBarcodeProducts
                ? 'transparent-contained-btn'
                : 'dark-contained-btn'
              }`}>
            {t('go_to_cart')}
          </button>
        )}
      </div>
    </div>
  )
}

export default SparCartHeader
