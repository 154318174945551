import { useNavigate } from 'react-router-dom'
import { Trans } from 'react-i18next'
import backArrow from '../../assets/icons/arrowBack.png'

import '../../styles/ui.scss'

const ArrowBack = () => {
  const navigate = useNavigate()

  return (
    <div className="arrow-back" onClick={() => navigate('/main')}>
      <img src={backArrow} alt="" />
      <span>
        <Trans i18nKey="back" />
      </span>
    </div>
  )
}

export default ArrowBack
