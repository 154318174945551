// General app action types
export const SET_LOADING = 'SET_LOADING'
export const SET_THEME = 'SET_THEME'
export const OPEN_SCREEN_SAVER = 'OPEN_SCREEN_SAVER'
export const SET_SHOW_NIX = 'SET_SHOW_NIX'
export const SET_SHOW_PRODUCT_DESCRIPTION = 'SET_SHOW_PRODUCT_DESCRIPTION'
export const AUTODIRECT_PAYMENT = 'AUTODIRECT_PAYMENT'
export const WAIT_AUTODIRECT_PAYMENT = 'WAIT_AUTODIRECT_PAYMENT'

// Main resources actions types
export const SET_ALL_RESOURCES = 'SET_ALL_RESOURCES'
export const SET_AUTH = 'SET_AUTH'
export const SET_USER = 'SET_USER'
export const SET_DEVICE = 'SET_DEVICE'
export const SET_STORE = 'SET_STORE'
export const SET_UUID = 'SET_UUID'
export const SET_CART = 'SET_CART'
export const IS_CALCULATING_CART = 'IS_CALCULATING_CART'
export const SET_SEARCH = 'SET_SEARCH'
export const SET_LAST_TRANSACTION = 'SET_LAST_TRANSACTION'
export const SET_CURRENT_TRANSACTION_TO_PRINT = 'SET_CURRENT_TRANSACTION_TO_PRINT'
export const SET_SETTINGS = 'SET_SETTINGS'
export const SET_KEYBOARD = 'SET_KEYBOARD'
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE'
export const SET_PRODUCTS = 'SET_PRODUCTS'
export const UPDATE_PRODUCTS_BY_ID = 'UPDATE_PRODUCTS_BY_ID'
export const DELETE_PRODUCTS_BY_ID = 'DELETE_PRODUCTS_BY_ID'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SELECTED_CATEGORY = 'SELECTED_CATEGORY'
export const SELECTED_BARCODE = 'SELECTED_BARCODE'
export const BARCODE_PRODUCT_NOT_FOUND = 'BARCODE_PRODUCT_NOT_FOUND'
export const IS_PAYMENT_RUNNING = 'IS_PAYMENT_RUNNING'

// Notification action types
export const REQUEST_SAMPLE_CHECK = 'REQUEST_SAMPLE_CHECK'
export const REQUEST_FULL_BASKET_CHECK = 'REQUEST_FULL_BASKET_CHECK'
export const REQUEST_AGE_CHECK = 'REQUEST_AGE_CHECK'
export const CONFIRMED_AGE_CHECK = 'CONFIRMED_AGE_CHECK'
export const REQUEST_ASK_HELP = 'REQUEST_ASK_HELP'
export const UNIQUE_EVENT_ID = 'UNIQUE_EVENT_ID'
export const PENDING_SAMPLE_CHECK = 'PENDING_SAMPLE_CHECK'
export const FULL_BASKET_CHECK_CART = 'FULL_BASKET_CHECK_CART'
