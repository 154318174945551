import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import { MdArrowBack } from 'react-icons/md'
import { hasAdminPin, getAdminPin } from '../../utils/settings'

import Logo from './Logo'
import LanguageSelection from './Language/LanguageSelection'
import AdminPIN from './Settings/AdminPIN'
import './../../styles/ui.scss'

const Header = React.memo(props => {
  const navigate = useNavigate()

  const location = useLocation()
  const { settings } = useSelector(state => state.resource)
  const { openScreenSaver } = useSelector(state => state.app)
  const [showAdminPIN, setShowAdminPIN] = useState(false)
  const [count, setCount] = useState(0)
  const [showBackArrow, setShowBackArrow] = useState(false)

  const goToSettings = () => {
    if (count === 2) {
      setCount(0)

      if (hasAdminPin(settings)) {
        setShowAdminPIN(true)
      } else {
        handleConfirmAdminPin()
      }
    } else {
      setCount(count + 1)
    }
  }

  const handleClickBack = () => {
    navigate('/main')
    setShowBackArrow(false)
  }

  const handleCancelAdminPin = () => {
    setShowAdminPIN(false)
  }

  const handleConfirmAdminPin = () => {
    handleCancelAdminPin()
    navigate('/settings')
    setShowBackArrow(true)
  }

  return (
    <div id="app-container">
      <header className="app-header">
        {showBackArrow ? (
          <MdArrowBack className="back-arrow" onClick={handleClickBack} />
        ) : (
          <Logo onClick={goToSettings} />
        )}

        {!openScreenSaver ||
          (!settings.enable_screen_saver &&
            location?.pathname !== '/welcome-page' && (
              <div className="lang-selection">
                <LanguageSelection />
              </div>
            ))}

        {showAdminPIN && (
          <AdminPIN
            handleCancel={handleCancelAdminPin}
            handleConfirm={handleConfirmAdminPin}
            adminPin={getAdminPin(settings)}
          />
        )}
      </header>
      <div id="children-container">{props.children}</div>
    </div>
  )
})

export default Header
