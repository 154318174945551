import React from 'react'
import { useSelector } from 'react-redux'
// import LOGO_ASSET_WHITE from './../../assets/images/countr_white.png'
import LOGO_ASSET_BLACK from './../../assets/images/countr_black.png'

const Logo = React.memo(({ isBlack, onClick }) => {
  const store = useSelector(state => state.resource.store)

  let storeLogo = isBlack ? LOGO_ASSET_BLACK : LOGO_ASSET_BLACK

  if (store.store_logo && store.store_logo.length) {
    storeLogo = store.store_logo
  }

  const handleClick = () => {
    onClick && typeof onClick === 'function' && onClick()
  }

  return (
    <img
      width="100%"
      height="100%"
      src={storeLogo}
      alt="Countr Logo"
      className={`logo ${onClick ? 'pointer' : ''}`}
      onClick={handleClick}
    />
  )
})

export default Logo
