import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import ReactPlayer from 'react-player'
import { Document, Page } from 'react-pdf'
import { ControlItem } from '@countr/ui'
import { Trans, useTranslation } from 'react-i18next'

import '../../styles/pages.scss'

const ScreenSaverSettings = props => {
  const { t } = useTranslation()
  const { settings, device } = useSelector(state => state.resource)
  const [showVideo, setShowVideo] = useState(false)

  const handleDelayTime = value => value.replace(/\d+.\d+/g, '')

  useEffect(() => {
    setTimeout(() => {
      setShowVideo(true)
    }, 1300)
  }, [])

  return (
    <div className="section">
      <div className="section-title">
        <span>
          <Trans i18nKey={`screen_saver`} />
        </span>
      </div>

      <div>
        <ControlItem
          title={<Trans i18nKey={`screen_saver_delay`} />}
          value={handleDelayTime(settings.screen_saver_delay)}
          handleChange={value =>
            props.handleChange('screen_saver_delay', value)
          }
          type="text"
        />
      </div>

      <div>
        <ControlItem
          title={<Trans i18nKey={`enable_screen_saver`} />}
          value={settings.enable_screen_saver}
          handleChange={value =>
            props.handleChange('enable_screen_saver', value)
          }
          type="boolean"
        />
      </div>

      <div className="section-title">
        <span>
          <Trans i18nKey={`screen_saver_info`} />
        </span>
      </div>

      <div>
        <p>
          <span>
            <Trans i18nKey={`type`} />
          </span>
          <span>{device.options?.adInfo?.adType ?? t('no_screen_saver')}</span>
        </p>
      </div>
      <div className="ad-preview">
        {device.options?.adInfo?.adType === 'Video' && (
          <div className="video-container">
            <ReactPlayer
              url={showVideo ? device.options.adInfo.adUrl : ''}
              playing={true}
              width="30vh"
              height="30vh"
              loop={true}
              volume={0}
              style={{ pointerEvents: 'none' }} // used to disable payers buttons
            />
          </div>
        )}

        {device.options?.adInfo?.adType === 'Photos' && (
          <>
            {!!device.options?.adInfo?.photoAdUrls?.length ? (
              <div className="images-container">
                {device.options.adInfo.photoAdUrls.map(image => (
                  <img alt="device-screen-saver" src={image} key={image} />
                ))}
              </div>
            ) : (
              <p>
                <Trans i18nKey={`no_screen_saver`} />
              </p>
            )}
          </>
        )}

        {device.options?.adInfo?.adType === 'PDF' && (
          <>
            {!!device.options?.adInfo?.pdfAdUrl ? (
              <div className="pdf-preview">
                <Document file={device.options.adInfo.pdfAdUrl}>
                  <Page pageNumber={1} />
                </Document>
              </div>
            ) : (
              <p>
                <Trans i18nKey={`no_screen_saver`} />
              </p>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ScreenSaverSettings
