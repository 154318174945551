import { CartUtils } from '@countr/utils'
import debounce from 'lodash.debounce'
import countrSdk from './Countr'


/**
 * Get last update cart from device or create one if it doesn't find
 * @param  {any} sdk - Countr sdk client
 * @param  {string} merchantId - Merchant id
 * @param  {string} storeId - Store id
 * @param  {DeviceObj} device - Device obj
 * @returns {CartObj} Return create/get cart obj or error if something went wrong
 */
export const getCart = (sdk, merchantId, storeId, device) => {
  try {
    return sdk.carts.read({ limit: 1, sort: '-created_at' }).then(carts => {
      const result = carts.filter(
        cart => cart.store === storeId && cart.device === device._id
      )

      if (!!result && !!result.length) {
        return result[0]
      } else {
        const newCart = CartUtils.createCart(merchantId, device, 1, 'kiosk')
        return sdk.carts.create(newCart)
      }
    })
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateCart = async (sdk, cart) => {
  try {
    const calculated = await sdk.carts.calculate(cart)
    const finalUpdate = await sdk.carts.update(calculated._id, { ...calculated, date: new Date() })

    if (calculated.server_modified) {
      finalUpdate.server_modified = true
    }
    
    return finalUpdate
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateCartServerDebounce = debounce(updateCart, 700, {
  leading: false,
  trailing: true
})

export const deleteCartEntry = (sdk, cart) => {
  updateCartServerDebounce(sdk, cart)
}

export const getDefaultReceipt = user => {
  if (user && user.receipt) {
    return user.receipt
  } else {
    return {
      header: [
        {
          type: 'image',
          content:
            'https://res.cloudinary.com/countr-prod/image/upload/v1466436772/logo-horizontal.png'
        },
        {
          type: 'text',
          content: 'Your store address',
          style: {
            alignment: 'center',
            fontSize: 'l',
            fontWeight: 'normal',
            fontStyle: 'normal'
          }
        },
        {
          type: 'text',
          content: 'Your text here',
          style: {
            alignment: 'center',
            fontSize: 's',
            fontWeight: 'bold',
            fontStyle: 'normal'
          }
        }
      ],
      footer: [
        {
          type: 'text',
          content: '',
          style: {
            alignment: 'center',
            fontSize: 'm',
            fontWeight: 'normal',
            fontStyle: 'normal'
          }
        }
      ]
    }
  }
}

export const returnProductDepositValue = product => {
  return product.options.deposit && product.options.deposit.price > 0
    ? product.options.deposit.price
    : 0
}

export const getTransactionTaxes = async transaction => {
  const taxes = []
  const countr = countrSdk

  const sdkTaxesCalculated = countr.calculateTotal(transaction)

  const parsedTaxes = Object.entries(sdkTaxesCalculated.taxes_breakdown).map(
    line => {
      return {
        name: `${line[0]} - ${line[0]}`,
        amount: line[1].preTax,
        above: line[1].postTax,
        rate: line[1].tax
      }
    }
  )

  taxes.push(...parsedTaxes)

  // Calculating items taxes
  // transaction.items.forEach(item => {
  //   const depositValue = returnProductDepositValue(item.product) * item.amount
  //   const depositTax = returnProductDepositTax(item.product)

  //   const aboveTax = depositValue / (1 + depositTax.amount)

  //   if (depositTax.name !== 'Zero - 0%') {
  //     const depositIndex = taxes.findIndex(t => t.name === depositTax.name)
  //     if (depositIndex === -1) {
  //       taxes.push({
  //         name: depositTax.name,
  //         amount: depositTax.amount,
  //         above: aboveTax,
  //         rate: depositTax.rate
  //       })
  //     } else {
  //       taxes[depositIndex].amount += depositTax.amount
  //       taxes[depositIndex].above += aboveTax
  //     }
  //   }
  // })

  // // Calulating delivery cost taxes (if it has)
  // if (
  //   !!transaction.extras.delivery &&
  //   !!transaction.extras.delivery.deliveryCost
  // ) {
  //   const cost = this.getDeliveryCost(transaction)
  //   const deliveryRate = this.getDeliveryRate(transaction)
  //   const feeTax = (cost * deliveryRate) / (1 + deliveryRate)
  //   const above = cost - feeTax

  //   const index = taxes.findIndex(t => t.rate === deliveryRate)
  //   if (index === -1) {
  //     taxes.push({
  //       name: `${deliveryRate * 100}% - ${deliveryRate * 100}%`,
  //       amount: feeTax,
  //       above: above,
  //       rate: deliveryRate
  //     })
  //   } else {
  //     taxes[index].amount += feeTax
  //     taxes[index].above += above
  //   }
  // }

  const sortTaxes = taxes.sort((a, b) => {
    if (a.rate < b.rate) {
      return -1
    } else if (a.rate > b.rate) {
      return 1
    } else {
      return 0
    }
  })

  return sortTaxes
}

export const getDeliveryCost = cart => {
  const { delivery } = cart.extras

  if (!!delivery) {
    const cost = delivery.deliveryCost || 0
    const decimal = delivery.decimalDigits || 2

    return cost / Math.pow(10, parseFloat(decimal))
  } else {
    return 0
  }
}

export const hashCode = s => {
  return s.split('').reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0)
    return a & a
  }, 0)
}
