import { Modal, Button } from '@countr/ui'
import { useTranslation } from 'react-i18next'

import './../../styles/screensaver.scss'

const TakeawayModal = ({ handleCancel, handleUpdateKdsOrder }) => {
  const { t } = useTranslation()

  const buttonStyle = { padding: 15, width: '85%', fontSize: 32 }

  const containerStyle = {
    height: 280,
    gap: 25,
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  return (
    <Modal
      title=""
      showCancelBtn={true}
      cancelLabel={t('cancel')}
      handleClose={handleCancel}
      showConfirmBtn={false}
      backdropClose={false}>
      <div style={containerStyle}>
        <Button
          label={t('takeaway')}
          onClick={handleUpdateKdsOrder.bind(this, 'takeaway')}
          styles={buttonStyle}
          color="primary"
        />
        <Button
          label={t('eat_in')}
          onClick={handleUpdateKdsOrder.bind(this, 'eat_in')}
          styles={buttonStyle}
          color="primary"
        />
      </div>
    </Modal>
  )
}

export default TakeawayModal
