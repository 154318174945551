import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import { CartUtils } from '@countr/utils'
import { CheckoutItem, CheckoutDetails, Divider, Modal } from '@countr/ui'

import countrSdk from '../utils/Countr'
import { deleteCartEntry, updateCart } from '../utils/cart'
import { setCart } from '../store/actions/resource'

const Checkout = () => {
  const { i18n, t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const store = useSelector(state => state.resource.store)
  const cart = useSelector(state => state.resource.cart)
  const setCartCb = useCallback(
    cart => {
      updateCart(countrSdk, cart)
      dispatch(setCart(cart))
    },
    [dispatch]
  )
  const emptyCart = useCallback(
    c => {
      const clenned = CartUtils.emptyCart(c)
      updateCart(countrSdk, clenned)
      dispatch(setCart(clenned))
    },
    [dispatch]
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleRemoveItem = item => {
    const updatedCart = deleteCartEntry(countrSdk, setCartCb, cart, item)

    if (!updatedCart.items.length) {
      cancelOrder()
    }
  }

  const proceedCheckout = () => {
    // TODO: Add tip modal as optional (when create a settings, add this feature)
    // After cancel/add go to payment page
    navigate('/payment')
  }

  const cancelOrder = () => {
    emptyCart(cart)
    setShowModal(false)
    navigate(-1)
  }

  const addMoreItems = () => {
    navigate(-1)
  }

  const handleCancel = () => {
    setShowModal(!showModal)
  }

  return (
    <div className="checkout-page">
      <div className="header">
        <span></span>
        <span>
          <Trans i18nKey="item" />
        </span>
        <span>
          <Trans i18nKey="quantity" />
        </span>
        <span>
          <Trans i18nKey="price" />
        </span>
        <span></span>
      </div>
      <Divider />
      <div className="items">
        {cart.items.map(item => (
          <React.Fragment key={CartUtils.getCartEntryId(item)}>
            <CheckoutItem
              item={item}
              store={store}
              handleChange={handleAmountChange}
              handleRemove={handleRemoveItem}
              language={i18n.language}
            />
            <Divider />
          </React.Fragment>
        ))}
      </div>
      <div className="sticky-bottom">
        <CheckoutDetails
          cart={cart}
          confirmBtn={<Trans i18nKey="pay" />}
          confirmBtnAction={proceedCheckout}
          cancelBtn={<Trans i18nKey="cancel" />}
          cancelBtnAction={handleCancel}
          neutralBtn={<Trans i18nKey="add_more_items" />}
          neutralBtnAction={addMoreItems}
          horizontalLayout={false}
          text={{
            total: <Trans i18nKey="total" />,
            subTotal: <Trans i18nKey="subtotal" />,
            taxes: <Trans i18nKey="taxes" />
          }}
        />
      </div>
      {showModal && (
        <Modal
          title={t(`empty_cart`)}
          handleClose={handleCancel}
          confirmAction={cancelOrder}
          cancelLabel={<Trans i18nKey="close" />}>
          <span>{t(`sign_out_header`)}</span>
        </Modal>
      )}
    </div>
  )
}

export default Checkout
