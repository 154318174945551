import { useState } from 'react'
import { Modal, Keyboard } from '@countr/ui'
import { toast } from 'react-toastify'
import { Trans } from 'react-i18next'

import './../../../styles/ui.scss'

const AdminPIN = ({ handleCancel, handleConfirm, adminPin }) => {
  // const { t } = useTranslation()
  const [pin, setPin] = useState('')

  const handleChange = value => {
    if (value === 'C') {
      setPin('')
    } else {
      setPin(`${pin}${value}`)
    }
  }

  const handleOk = () => {
    if (pin === adminPin) {
      handleConfirm()
    } else {
      toast.error('Wrong Admin PIN, plese try again.')
      setPin('')
    }
  }

  return (
    <Modal
      title="Admin PIN"
      handleClose={handleCancel}
      confirmAction={handleOk}
      showConfirmBtn={true}
      disableConfirm={pin.length < 4}
      cancelLabel={<Trans i18nKey="close" />}
      confirmLabel={<Trans i18nKey="ok" />}>
      <div className="admin-pin">
        <span>{'*'.repeat(pin.length)}</span>
        <Keyboard
          handleChange={handleChange}
          showDot={false}
          style={{ width: '100%' }}
        />
      </div>
    </Modal>
  )
}

export default AdminPIN
