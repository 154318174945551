import { useCallback, useState } from 'react'
import { Trans } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import countrSdk from '../../utils/Countr'
import { setDevice, setSettings } from '../../store/actions/resource'
import { updateDevice } from '../../utils/device'

import AccountSettings from './AccountSettings'
import ScreenSaverSettings from './ScreenSaverSettings'
import '../../styles/pages.scss'

const Settings = () => {
  const dispatch = useDispatch()
  const { device, settings } = useSelector(state => state.resource)
  const updateSettings = useCallback(d => dispatch(setSettings(d)), [dispatch])
  const setDeviceCb = useCallback(
    d => {
      dispatch(setDevice(d))
      updateDevice(countrSdk, d)
    },
    [dispatch]
  )
  const [activeTab, setActiveTab] = useState('account')

  const handleTabChange = value => {
    setActiveTab(value)
  }

  const handleActiveTab = value => {
    if (value === activeTab) {
      return 'active-tab'
    }
    return ''
  }

  const handleChange = (key, newValue) => {
    const updatedSettings = {
      ...settings,
      [key]: newValue
    }
    updateSettings(updatedSettings)
    setDeviceCb({
      ...device,
      settings: {
        ...updatedSettings
      }
    })
  }

  return (
    <div className="settings-page">
      <div className="section-title">
        <div className="setting-tabs">
          <button
            className={handleActiveTab('account')}
            onClick={() => handleTabChange('account')}>
            <Trans i18nKey={`account`} />
          </button>
          <div />
          <button
            className={handleActiveTab('screen_saver')}
            onClick={() => handleTabChange('screen_saver')}>
            <Trans i18nKey={`screen_saver`} />
          </button>
        </div>
      </div>

      {activeTab === 'account' && (
        <div>
          <AccountSettings handleChange={handleChange} />
        </div>
      )}

      {activeTab === 'screen_saver' && (
        <div>
          <ScreenSaverSettings handleChange={handleChange} />
        </div>
      )}
    </div>
  )
}

export default Settings
