import { useState, useEffect } from 'react'
import EndPageImg from '../assets/images/endpage.png'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const EndPage = () => {
  const navigate = useNavigate()
  const [counter, setCounter] = useState(2)

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    if (!counter) {
      navigate('/welcome-page')
    }
    return () => clearInterval(timer)
  }, [counter, navigate])

  return (
    <div className="end-page">
      <div className="vector">
        <img src={EndPageImg} alt={EndPageImg} />
      </div>
      <div className="endpage-info">
        <div className="endpage-text">
          <h1>
            <Trans i18nKey="you_paid" /> <br /> <Trans i18nKey="end_msg" />{' '}
          </h1>
          <div className="endpage-close-msg">
            <p>
              <Trans i18nKey="close_auto" />{' '}
              <span style={{ fontWeight: '800' }}>
                {counter} <Trans i18nKey="seconds" />
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EndPage
