import { useEffect, useState } from 'react'
import { MdCheck, MdClose } from 'react-icons/md'
import { FormatCurrency, AddRemoveButtons } from '@countr/ui'
import { AddonUtils } from '@countr/utils'

import './components.scss'

const AddonsGroupModal = ({
  product,
  handleChange,
  store,
  language = 'en'
}) => {
  const { _id, symbol } = store.currency
  const [groups, setGroups] = useState([])
  const [selectedAddons, setSelectedAddons] = useState([])

  useEffect(() => {
    const defaultGroups = product.addonGroups.filter(group =>
      AddonUtils.isGroupValid(group)
    )
    defaultGroups.forEach(group => {
      group._id.addons = group._id.addons.map(addon => ({
        ...addon,
        amount: 0
      }))
    })
    setGroups(defaultGroups)
    handleChange(defaultGroups)
  }, [product.addonGroups, handleChange])

  const addAmount = (group, addon) => {
    // Check if max is reached and return
    const groupAmount = group._id.addons.reduce(
      (acc, crr) => acc + crr.amount,
      0
    )
    if (group?.max && group.max <= groupAmount) {
      return
    }

    const defaultGroups = [...groups]
    defaultGroups.forEach(g => {
      if (g._id._id === group._id._id) {
        group._id.addons = group._id.addons.map(a =>
          a._id === addon._id ? { ...a, amount: a.amount + 1 } : { ...a }
        )
      }
    })

    updateSelectedAddons(addon, 'add')
    setGroups(defaultGroups)
    handleChange(defaultGroups)
  }

  const removeAmount = (group, addon) => {
    const defaultGroups = [...groups]
    defaultGroups.forEach(g => {
      if (g._id._id === group._id._id) {
        group._id.addons = group._id.addons.map(a =>
          a._id === addon._id
            ? { ...a, amount: a.amount > 1 ? a.amount - 1 : 0 }
            : { ...a }
        )
      }
    })

    updateSelectedAddons(addon, 'remove')
    setGroups(defaultGroups)
    handleChange(defaultGroups)
  }

  const validateGroup = group => {
    const list = [...group._id.addons]
    return AddonUtils.validateGroup(list, group)
  }

  const updateSelectedAddons = (addon, action) => {
    const updated = [...selectedAddons]
    const existing = selectedAddons.findIndex(a => a._id === addon._id)
    const amountToAdd = action === 'add' ? 1 : -1

    if (existing > -1) {
      updated[existing].amount += amountToAdd
      if (updated[existing].amount < 1) {
        updated.splice(existing, 1)
      }
    } else {
      const addonCoppy = { ...addon }
      addonCoppy.amount += amountToAdd
      if (addonCoppy.amount > 0) {
        updated.push(addonCoppy)
      }
    }
    setSelectedAddons(updated)
  }

  return (
    <>
      <div className="selected-addon-tiles-list">
        {selectedAddons.map(selected => (
          <div key={selected._id} className="selected-addon-tile">
            {selected.name} x {selected.amount}
          </div>
        ))}
      </div>

      <div className="addon-groups">
        {groups.map(group => (
          <div key={group._id._id}>
            <header>
              <span>
                {group._id.name} - (Min:{' '}
                {AddonUtils.getGroupMaxMin(group, 'min')} / Max:{' '}
                {AddonUtils.getGroupMaxMin(group, 'max')})
              </span>
              <span className="validate">
                {validateGroup(group) ? (
                  <MdCheck className="valid" />
                ) : (
                  <MdClose className="invalid" />
                )}
              </span>
            </header>
            <div className="addon-selection">
              {group._id.addons.map(addon => (
                <div key={addon._id} className="addon">
                  <span
                    className="title"
                    onClick={() => addAmount(group, addon)}>
                    {AddonUtils.getAddonName(addon, language)}{' '}
                    {AddonUtils.getAddonPrice(addon, _id) > 0 && (
                      <FormatCurrency
                        value={AddonUtils.getAddonPrice(addon, _id)}
                        currency={symbol}
                      />
                    )}
                  </span>
                  <span className="control">
                    <AddRemoveButtons
                      value={addon.amount}
                      handleAdd={() => addAmount(group, addon)}
                      handleRemove={() => removeAmount(group, addon)}
                      containerStyle={{ width: '100%' }}
                    />
                  </span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default AddonsGroupModal
