import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { deviceDetect } from 'react-device-detect'
import { toast } from 'react-toastify'
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'

import { Login as LoginComponent, RegisterDevice } from '@countr/ui'

import countrSdk from '../utils/Countr'
import {
  checkDevice,
  getLastTransaction,
  loadDeviceSettings
} from '../utils/device'
import { getCart } from '../utils/cart'
import { setAllResources, setUUID } from '../store/actions/resource'
import { getCurrentTheme, hasTheme } from '../utils/store'
import { setTheme } from '../store/actions/app'
import './../styles/pages.scss'

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const uuid = useSelector(state => state.resource.uuid)
  const [showRegistration, setShowRegistration] = useState(false)
  const setAllCb = useCallback(r => dispatch(setAllResources(r)), [dispatch])
  const setUUIDCb = useCallback(u => dispatch(setUUID(u)), [dispatch])
  const setThemeCb = useCallback(t => dispatch(setTheme(t)), [dispatch])
  const checkTheme = useCallback(
    store => {
      const currentTheme = getCurrentTheme(store)
      setThemeCb(currentTheme)
    },
    [setThemeCb]
  )

  const onLoginSuccess = async () => {
    let currentUUID = uuid

    if (!currentUUID || !currentUUID.includes('kiosk-')) {
      const fp = await FingerprintJS.load({
        apiKey: 'qWrdj7r6z8RlqD9KlAwy',
        endpoint: [
          'https://fp.countrhq.com',
          FingerprintJS.defaultEndpoint // The default endpoint as fallback
        ]
      })
      const result = await fp.get({ extendedResult: true })

      const parsedUUID = `kiosk-${result.visitorId}`

      setUUIDCb(parsedUUID)
      currentUUID = parsedUUID
    }

    const device = await checkDevice(countrSdk, `${currentUUID}`)
    onSuccess(device)
  }

  const onSuccess = async device => {
    if (device) {
      const { merchant, store } = device

      const cart = await getCart(countrSdk, merchant._id, store._id, device)
      const lastTransaction = await getLastTransaction(countrSdk, device)
      const settings = loadDeviceSettings(device)

      setAllCb({
        user: merchant,
        uuid,
        device,
        store,
        cart,
        lastTransaction: lastTransaction ?? 0,
        settings
      })

      if (hasTheme(store)) {
        checkTheme(store)
      }

      navigate('/main')
    } else {
      toast.info('Device not found, please create a new device.')
      setShowRegistration(true)
    }
  }

  const onError = error => {
    const msg = !!error.message ? error.message : JSON.stringify(error)
    toast.error(msg)
  }

  return (
    <div className="login-page">
      {showRegistration ? (
        <RegisterDevice
          sdk={countrSdk}
          onSuccess={onSuccess}
          onError={onError}
          uuid={uuid}
          info={deviceDetect()}
        />
      ) : (
        <LoginComponent
          sdk={countrSdk}
          onSuccess={onLoginSuccess}
          onError={onError}
        />
      )}
    </div>
  )
}

export default Login
