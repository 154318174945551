import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { setCart } from '../../../../store/actions/resource'

import TotalSection from './TotalSection'
import ButtonActionSection from './ButtonActionSection'
import TakeawayModal from '../../TakeawayModal'

import '../../../../styles/sparCart.scss'

const SparCartFooter = ({
  cart,
  showFinishBasketCheck,
  handleShowFinishBasketCheck
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { cart: activeCart } = useSelector(state => state.resource)

  const [showTakeawayModal, setShowTakeawayModal] = useState(false)

  const handleGoToCheckout = async () => {
    navigate('../payment')
  }

  const handleUpdateKdsOrder = type => {
    const cartCopy = { ...activeCart }
    if (type === 'takeaway') {
      cartCopy.extras.takeaway = true
    } else {
      cartCopy.extras.takeaway = false
    }

    dispatch(setCart(cartCopy))
    setShowTakeawayModal(false)
  }
  return (
    <div id="spar-cart-footer">
      <TotalSection items={cart.items} />
      <ButtonActionSection
        total={cart.total}
        goToCheckout={handleGoToCheckout}
        showFinishBasketCheck={showFinishBasketCheck}
        handleShowFinishBasketCheck={handleShowFinishBasketCheck}
      />

      {showTakeawayModal && (
        <TakeawayModal
          handleCancel={setShowTakeawayModal.bind(this, false)}
          handleUpdateKdsOrder={handleUpdateKdsOrder}
        />
      )}
    </div>
  )
}

export default SparCartFooter
