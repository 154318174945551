import { useEffect } from 'react'
import { Trans } from 'react-i18next'
import { MdCheckCircle, MdClose } from 'react-icons/md'
import './../../../styles/checkout.scss'

const ResultPayment = ({ transaction, handleCloseFinishedPayment }) => {
  useEffect(() => {
    setTimeout(() => {
      handleCloseFinishedPayment()
    }, 2000)
  })
  
  return (
    <div className="result-payment">
      <div className="status">
        {!!transaction && !!transaction._id ? (
          <MdCheckCircle className="icon ok" />
        ) : (
          <MdClose className="icon fail" />
        )}
      </div>
      <div className="message">
        <span>
          {!!transaction && !!transaction._id ? (
            <Trans i18nKey="payment_success" />
          ) : (
            <Trans i18nKey="payment_not_successful" />
          )}
        </span>
      </div>
    </div>
  )
}

export default ResultPayment
